import React, { Component } from 'react';


class ServiceCard extends Component {


  render(){
    return (      
      <div className="service-card articles-grid__grid-item">
        {this.props.img ?
          <div className="service-card__image-wrap">
            <img className="service-card__image" src={this.props.img} alt=""/>
          </div>
        : ''}
        <div className="service-card__content">
          <div className="service-card__subtitle">{this.props.caption}&nbsp;</div>
          <div className="service-card__title-row">
            <h3 className="service-card__title">{this.props.title}</h3>
            <div className="service-card__price" dangerouslySetInnerHTML={{ __html:this.props.price + ' Р'}}></div>
          </div>
          <div className="service-card__buttons">
            <div className="service-card__buttons-row">
              {this.props.baseId ?
                this.props.complexId ? 
                  <button className="button-2 button-2_theme_white button-2_sz_sm service-card__buttons-item" data-service={this.props.baseId} onClick={this.props.showFunction}>Экстерьер</button>
                : <button className="button-2 button-2_theme_white button-2_sz_sm service-card__buttons-item" data-service={this.props.baseId} onClick={this.props.showFunction}>Подробнее</button>
              : ''}
              {this.props.complexId ?
                <button className="button button_theme_gray button_sz_sm service-card__buttons-item" data-service={this.props.complexId} onClick={this.props.showFunction}>Комплексная</button>
              : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceCard;
