import React, { Component } from 'react';

import WasherMap from './WasherMap'
import Button from './Button'

class ContactInfo extends Component {

  constructor(props){
		super(props);

		this.state = {
      hideMap: true
    };
  }

  componentDidMount(){
    setTimeout(()=> {
      this.setState({
        hideMap: false
      });
    }, 1000 * this.props.index)
  }


  render(){

    let anchorId = {
      '1': '#voennay',
      '2': '#bolshev',
      '3': '#energy',
    }

    return (      
      <div className="contact-info contacts-grid__grid-item" id={anchorId[this.props.idContact]}>
        <div className="contact-info__picture" id="contacts-map-1">
          {!this.state.hideMap ? <WasherMap points = {this.props.geo.split(',')}/> : ''}
        </div>
        <div className="contact-info__content">
          <div className="contact-info__header contact-info__item">
            <h3 className="contact-info__title">{this.props.address}</h3>
          </div>
          <div className="contact-info__links contact-info__item">
            <ul className="contact-info__links-list">
              <li className="contact-info__links-item"><a className="contact-info__link contact-info__link_icon_phone" href={'tel:' + this.props.phone}>{this.props.phone}</a></li>
              <li className="contact-info__links-item"><a className="contact-info__link contact-info__link_icon_mail" href={'mailto:' + this.props.mail}>{this.props.mail}</a></li>
            </ul>
          </div>
          <div className="contact-info__buttons contact-info__item">
            <div className="contact-info__buttons-row">
              <Button className='contact-info__buttons-item button button_fz_sm' to= '/registration/'>Записаться</Button>
              <Button className='contact-info__buttons-item button-2 button-2_fz_sm' to= {'/addresses' + anchorId[this.props.idContact]}>Инфо о мойке</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
