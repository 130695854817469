import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {registrationContext} from '../../context';

import Datepicker from '../Datepicker'
import TimeList from '../TimeList'
import Button from '../Button'

class Step2 extends Component {

  static contextType = registrationContext;

  componentDidMount(){
    this.context.stepNumber(this.props.location.pathname)
    if (this.props.location.state) {
      this.context.checkWasher(this.props.location.state)
    }

    setTimeout( () => {
      this.context.checkServices('', '')
      this.context.checkTime('')
    }, 900)
  }

  buttonType() {
    if(this.context.time) {
      return <Button className='button' to= 'step-3' >Дальше</Button>
    } else {
      return <Button className='button is-disable' disabled title='Выберите дату и время'>Дальше</Button>
    }
  }

  render(){
    if(!this.context.carWasherId) {
      if(!this.props.location.state) {
        return <Redirect to='/registration/' />
      }
    }

    return ( 
      <div>          
        <div className="sing-up__body">
          <div className="date-select">
            <Datepicker />
            <TimeList key={this.context.carWasherId}/>
          </div>
        </div>
        <div className="sing-up__footer">
          <div className="sing-up__footer-wrap">
            {this.buttonType()}
            <Button className='button button_text' to='/registration/'>Назад</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
