import React from 'react';
import { NavLink } from "react-router-dom";

function DesktopMenu(props) {
  return (
    <nav className='desktop-menu'>
      <div className="desktop-menu__item">
        <NavLink className="desktop-menu__link" activeClassName="is-active" activeStyle={{pointerEvents: "none"}} to="/services/" onClick={props.closeMenu}>Услуги</NavLink>
      </div>
      <div className="desktop-menu__item">
        <span className="desktop-menu__link desktop-menu__link_drop">О нас</span>
        <div className="desktop-menu__submenu">
          <NavLink className="desktop-menu__sublink" to='/about-company/' onClick={props.closeMenu}>О компании</NavLink>
          <NavLink className="desktop-menu__sublink" to='/jantar-technology/' onClick={props.closeMenu}>Янтарь.<span>Technology</span></NavLink>
          <NavLink className="desktop-menu__sublink" to='/jantar-selection/' onClick={props.closeMenu}>Янтарь.<span>Selection</span></NavLink>
          <NavLink className="desktop-menu__sublink" to='/jantar-team/' onClick={props.closeMenu}>Янтарь.<span>ProTeam</span></NavLink>
        </div>
      </div>
      <div className="desktop-menu__item">
        <NavLink className="desktop-menu__link" activeClassName="is-active" to="/app/" onClick={props.closeMenu}>Приложение</NavLink>
      </div>
      <div className="desktop-menu__item">
        <NavLink className="desktop-menu__link" activeClassName="is-active" activeStyle={{pointerEvents: "none"}} to="/recommendations/" onClick={props.closeMenu}>Программы рекомендаций</NavLink>
      </div>
      <div className="desktop-menu__item">
        <NavLink className="desktop-menu__link" activeClassName="is-active" activeStyle={{pointerEvents: "none"}} to="/corporate/" onClick={props.closeMenu}>Корпоративным клиентам</NavLink>
      </div>
      <div className="desktop-menu__item">
        <NavLink className="desktop-menu__link" activeClassName="is-active" activeStyle={{pointerEvents: "none"}} to="/contacts/" onClick={props.closeMenu}>Контакты</NavLink>
      </div>
    </nav>
  );
}

export default DesktopMenu;
