import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group"
import {carWahshesContext, registrationContext} from '../context';

class StepInfo extends Component {

  static contextType = registrationContext;



  render(){

    let mounthList = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    let timeItem = '';
    if(this.context.time) {
      timeItem = this.context.time.getDate() + ' ' + mounthList[this.context.time.getMonth()] + ' ' + this.context.time.getFullYear() + ', ' + (this.context.time.getHours()<10 ? '0'+this.context.time.getHours():this.context.time.getHours()) + ':' + (this.context.time.getMinutes()<10 ? '0'+this.context.time.getMinutes() : this.context.time.getMinutes())
    }
    return (      
      <div className="step-info__list">
        <TransitionGroup>
          <CSSTransition
            key={this.context.carWasherId}
            timeout={300}
            classNames="step-link"
          >
            <div>
              <carWahshesContext.Consumer>
                {(carWahshes) => {
                  return (
                    carWahshes.length > 0 ? carWahshes.map( item => (
                      item.PROPERTY_VALUES.EPR_CARWASH_ID === this.context.carWasherId ? <Link className="step-info__item" to="/registration/">{item.PROPERTY_VALUES.ADRESS}</Link> : ''
                    )) : ''
                  )
                }}
              </carWahshesContext.Consumer>
            </div>
          </CSSTransition>
        </TransitionGroup>    
        
        <TransitionGroup>
          <CSSTransition
            key={this.context.time}
            timeout={300}
            classNames="step-link"
          >
            <div>
              {this.context.time ?
                this.context.stepLink !== '/registration/step-2' ?
                  <Link className="step-info__item" to="step-2">{timeItem}</Link> 
                : <span className="step-info__item">{timeItem}</span> 
              :''}
            </div>
          </CSSTransition>
        </TransitionGroup>    

        <TransitionGroup>
          <CSSTransition
            key={this.context.serviceName}
            timeout={300}
            classNames="step-link"
          >
            <div>
              {this.context.serviceName ? 
                this.context.stepLink !== '/registration/step-3' ?
                  <Link className="step-info__item" to="step-3" dangerouslySetInnerHTML={{ __html:this.context.serviceName}}></Link>
                  : <span className="step-info__item" dangerouslySetInnerHTML={{ __html:this.context.serviceName}}></span>
              :''}
            </div>
          </CSSTransition>
        </TransitionGroup>    
      </div>

    );
  }
}

export default StepInfo;
