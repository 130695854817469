import React, { Component } from 'react';
import Button from '../Button'


class MainBlock extends Component {

  videoBackground(){
    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }

    if (this.props.video) {
      let countVideo = this.props.video.videoMp4.length;
      let numberVideo = getRandomInt(countVideo);
      return(   
        <video className="main-block__video" loop="loop" autoPlay preload="auto" muted>
          <source src={this.props.video.videoWebm[numberVideo]}  type="video/webm;" />
          <source src={this.props.video.videoMp4[numberVideo]} type="video/mp4;" />
        </video>
      )
    } 
  }

  render(){

    
    if(document.readyState === "complete") {
      var elm = document.querySelector(".main-block__video-wrapper")
      if(elm) {
        elm.classList.add("is-active")
      }
    } else {
      window.onload = () => {
        var elm = document.querySelector(".main-block__video-wrapper")
        elm.classList.add("is-active")
      };
    }

    return (
      <section className={'main-block ' + (this.props.class ? this.props.class : '') } style={{backgroundImage: 'url(' + this.props.img + ')'}}>
        <div className='main-block__prev-button js-prev-scroll'></div>
          <div className="main-block__video-wrapper">
            {this.videoBackground()}
          </div>     
        <div className="main-block__wrapper">
          
          <div className="main-block__content">
            <div className="main-block__head">
              {this.props.subtitle ? <div className="main-block__subtitle" dangerouslySetInnerHTML={{ __html: this.props.subtitle }}></div>: ''}
              <div className="main-block__title" dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
            </div>

            <div className="main-block__body">
              {this.props.children}
            </div>
            {this.props.buttonText ? <div className="main-block__footer"> <Button className='button' to={this.props.buttonto}>{this.props.buttonText}</Button></div> : ''}
          </div>
        </div>
      </section>
    );
  }
}

export default MainBlock;
