import React, { Component } from 'react';
import {servicesContext} from '../context';

import Button from './Button'


class ProgramGrid extends Component {
  render(){
    
    function endings(number) {
      let day;
      switch (number) {
        case 0 :
          day = 'дней'
          break;
        case 1 :
          day = 'день'
          break;
        case 2 :
          day = 'дня'
          break;
        case 3 :
          day = 'дня'
          break;
        case 4 :
          day = 'дня'
          break;
        default:
          day = 'дней';
      }
      return day      
    }
    
    function service(list, id) {
      let title;

      for (var key in list) {
        if (list[key].ID === id) {
          title = list[key].NAME
        }
      }
      return title      
    }

    
    return (      
      <div className="program-grid care-subsection__title">
        <div className="program-grid__box">
        <servicesContext.Consumer>
          {(services) => {
            return (
              this.props.list.length > 0 ? this.props.list.map((item) => (
                <div className="program-event program-grid__item">
                  <div className="program-event__date">Через {item.BREAK_TIME / 86400 + ' ' + endings(item.BREAK_TIME / 86400)}</div>
                  <div className="program-event__title">{service(services, item.SERVICE_ID)}</div>
                  <div className="program-event__buttons">
                    <div className="program-event__buttons-row">
                      <Button className='program-event__buttons-item button-2 button-2_theme_white button-2_sz_sm' to= '/services/'>Инфо</Button>
                      <Button className='program-event__buttons-item button button_theme_gray button_sz_sm' to= '/registration/'>Записаться</Button>
                    </div>
                  </div>
                </div>
              )): ''
            )
          }}
        </servicesContext.Consumer>
        </div>
      </div>
    );
  }
}

export default ProgramGrid;
