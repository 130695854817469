import React, { Component } from 'react';
import {registrationContext} from '../../context';

// import Button from '../Button'
import AddressListReg from '../AddressListReg'

class Step5Failed extends Component {

  static contextType = registrationContext;

  componentDidMount(){
    this.context.stepNumber(this.props.location.pathname)
  }


  render(){
    return (  
      <div>    
        <div className="sing-up__body">
          <AddressListReg />
        </div>
        <div className="sing-up__footer">
          <div className="sing-up__footer-wrap">
            <a className='button' href='/'>На главную</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Step5Failed;
