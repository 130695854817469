import React, { Component } from 'react';
import {registrationContext} from '../context';
import moment from 'moment'
import $ from 'jquery'


class TimeList extends Component {

  constructor(props){
		super(props);

		this.state = {
      checkTime: '',
      emptyTime: ''
    };
  }

  static contextType = registrationContext;

	tabActive = (e) => {
    e.preventDefault();
    for (var i = 0; i < e.target.parentElement.children.length; i++) {
      e.target.parentElement.children[i].classList.remove('is-active')
    }
    e.target.classList.add('is-active')

    var id = e.target.getAttribute('href');
    var element = $(id);
    var index = element.index();
    var height = element.height();
    $('.time-list__list').animate({
      scrollTop: index * height
    }, 500);
  }

  render(){

    function timeList(contextDate) {
      let checkTime = moment(contextDate.context.time)
      let timeList = [];
      let tabItem = {};
      let tabLink = [];

      for(var key in contextDate.context.timeList) {
        let dateStart = moment(contextDate.context.timeList[key].DATE_START)
        // let dateEnd = moment(contextDate.context.timeList[key].DATE_END)
        let partDay;        

        if (dateStart.get('hours') < 6) {
          partDay = 'night';
          if(!tabItem.night) {
            tabItem = Object.assign(tabItem, {night: 'Ночь'})
          }
        } else if(dateStart.get('hours') < 12) {
          partDay = 'morning';
          if(!tabItem.morning) {
            tabItem = Object.assign(tabItem, {morning: 'Утро'})
          }
        } else if(dateStart.get('hours') < 18) {
          partDay = 'day';
          if(!tabItem.day) {
            tabItem = Object.assign(tabItem, {day: 'День'})
          }
        } else if(dateStart.get('hours') <= 23) {
          partDay = 'evening';
          if(!tabItem.evening) {
            tabItem = Object.assign(tabItem, {evening: 'Вечер'})
          }
        } else {
          partDay = ''
        }

        timeList.push(
          <label className="time-list__item" id={partDay} >
            <input className="time-list__radio" type="radio" name="time" checked={dateStart.format('HH:mm') === checkTime.format('HH:mm')} onChange={contextDate.context.checkTime.bind(this, new Date(dateStart))}/>
            <span className="time-list__name">{dateStart.format('HH:mm')}</span>
          </label>
        )
      }
      
      for(let key in tabItem) {
        tabLink.push(
          <a className="time-list__anchor js-anchor" href={"#" + key} onClick={contextDate.tabActive}>{tabItem[key]}</a>
        )
      }

      return <div className="time-list">
              <div className="time-list__head">
                {tabLink}
              </div>
              <div className="time-list__body">
                <div className="time-list__list">
                  {timeList}
                </div>
              </div>
            </div>
    }

    return (      
      timeList(this)
    );
  }
}

export default TimeList;
