import React, { Component } from 'react';

import {servicesContext} from '../context';

class RecomendationsList extends Component {


  render(){

    function service(list, id) {
      let title;

      for (var key in list) {
        if (list[key].ID === id) {
          title = list[key].NAME
        }
      }
      return title      
    }
    
    return (      
      <div className="recomendations-list care-subsection__title">
        <ul className="recomendations-list__box">
          <servicesContext.Consumer>
            {(services) => {
              return (
              this.props.list.length > 0 ? this.props.list.map((item) => (
                <li className="recomendations-list__item">
                  <div className="recomendations-list__item-title">{service(services, item.SERVICE_ID)}</div>
                  <div className="recomendations-list__item-subtitle">{item.INTENSITY_DESCRIPTION}</div>
                </li>
              )): ''
            )
          }}
          </servicesContext.Consumer>
        </ul>
      </div>
    );
  }
}

export default RecomendationsList;
