import React from 'react';
import {carWahshesContext} from '../context';

function ContactList() {
  return (
    <carWahshesContext.Consumer>
      {(carwashes) => {
        return (
          <div className="contact-list contact-list_dark">
            {carwashes.length > 0 ? carwashes.map((item) => (
              <a className="contact-list__item" href={'tel:' + item.PROPERTY_VALUES.PHONE}>
                <span className="contact-list__title">{item.PROPERTY_VALUES.ADRESS}</span>
                <span className="contact-list__value">{item.PROPERTY_VALUES.PHONE}</span>
              </a>
            )):''}
          </div>
        )
      }}
    </carWahshesContext.Consumer>
  );
}

export default ContactList;
 