import React from 'react';
import {Link} from "react-router-dom";

import logo from '../media/img/logo.svg'

function Logo(props) {
  return (
    <Link className="logo" to="/" onClick={props.closeMenu}>
      <img className="logo__img" src={logo} alt="" role="presentation"/>
    </Link>
  );
}

export default Logo;
