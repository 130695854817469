import React, {Component} from 'react';
import {YMaps, Map, Placemark} from 'react-yandex-maps';



class WasherMap extends Component {
	
	render(){

		return (
			<YMaps>
				<Map
					defaultState={{center: this.props.points, zoom: 13}}
					width="100%"
					height="100%"
					>					
					<Placemark
						defaultGeometry={this.props.points}
					/>
				</Map>
			</YMaps>
		);
	}
}

export default WasherMap;
