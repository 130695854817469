import React, { Component } from 'react';

class RadioButton extends Component {

  render(){
    return (      
      <div className="checkbox">
        <input className="checkbox__input" name='serviceList' type="radio" value={this.props.title} id={this.props.id} onChange={this.props.handleFunction(this.props.id)}/>
        <label className="checkbox__label" htmlFor={this.props.id}> 
          <span className="checkbox__title">{this.props.title}</span>
          <span className="checkbox__subtitle">{this.props.subtitle}</span>
        </label>
      </div>
    );
  }
}

export default RadioButton;
