import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import AddressList from '../components/AddressList'
import MainBlock from '../components/main-page/MainBlock'
import MainBlockText from '../components/main-page/MainBlockText'
import TechList from '../components/TechList'
import Hashtags from '../components/Hashtags'
import $ from 'jquery'
import throttle from 'lodash.throttle'

import mainBg1 from '../media/img/components/main-block/bg-1.jpg'
import mainBg2 from '../media/img/components/main-block/bg-2.jpg'
import mainBg3 from '../media/img/components/main-block/bg-3.jpg'
import mainBg4 from '../media/img/components/main-block/bg-4.jpg'
import mainBg5 from '../media/img/components/main-block/bg-5.jpg'
import mainBg6 from '../media/img/components/main-block/bg-6.jpg'
import appImg from '../media/img/components/main-block/app.png'

class Main extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      loadVideo: false,
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('jantar.main').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 

    $('.main-block').on('click', function () {
      var activeBlock = $('.main-block.is-active');
      var widthBlock = activeBlock.width();
      var indexBlock = $(this).index();
  
      if (!$(this).hasClass('is-active')) {
        $(this).addClass('is-active');
        $(this).siblings().removeClass('is-active');
        $('.js-hor-scroll').animate({
          scrollLeft: widthBlock * indexBlock
        }, 800);
      }
    }); 

    $('.main-block .js-prev-scroll').on('click', function(){
      var activeBlock = $('.main-block.is-active');
      var widthBlock = activeBlock.width();
      var indexBlock = $(this).parents('.main-block').index();
      $(this).parents('.main-block').addClass('is-active');
      $(this).parents('.main-block').siblings().removeClass('is-active');
      $('.js-hor-scroll').animate({scrollLeft: (widthBlock * (indexBlock - 1))}, 800);  
    });
  
    // Горизонтальный скролл главной страницы
    function changeSlide(delta) {
      var activeBlock = $('.main-block.is-active');
      var widthBlock = activeBlock.width();
      var indexBlock = activeBlock.index();
      if (delta < 0) {
        $('.js-hor-scroll').animate({scrollLeft: (widthBlock * (indexBlock + 1))}, 600);  
      } else {
        $('.js-hor-scroll').animate({scrollLeft: (widthBlock * (indexBlock - 1))}, 600);  
      }
    }

    var trottleChangeTrackPad = throttle(changeSlide, 1000, { 'trailing': false });
    var trottleChange = throttle(changeSlide, 600, { 'trailing': false });

    var arrTime = [];
    $('.js-hor-scroll').mousewheel(function(e, delta){
      var time = new Date();
      arrTime.push(time)
      var difTime = arrTime[(arrTime.length - 1)] - arrTime[(arrTime.length - 2)];
      console.log(e.deltaFactor)
      if ( e.deltaFactor > 70 ) {
        // console.log(difTime + '==' + delta)
        trottleChange(delta);
      } else if((difTime < 8) && (Math.abs(delta) > 1)) {
        // console.log(difTime + ' =!!= ' + delta)
        trottleChangeTrackPad(delta);
      } else {
        e.preventDefault()
      }
    });

    $(window).keydown(function(eventObject) {
      if(eventObject.which === 39) {
        trottleChange(-1);
      } else if(eventObject.which === 37) {
        trottleChange(1);
      }
    })
  
    if ($(window).width() > 768) {
      $('.js-hor-scroll').scroll(function (e) {
        var activeBlock = $('.main-block.is-active');
        var widthBlock = activeBlock.width();
        var windowBlock = $(window).width();
        var positionLeft = activeBlock.position().left;
  
        if (positionLeft >= widthBlock) {
          activeBlock.removeClass('is-active');
          activeBlock.prev().addClass('is-active');
        } else if (positionLeft < -windowBlock + widthBlock) {
          activeBlock.removeClass('is-active');
          activeBlock.next().addClass('is-active');
        }
      });
    }
  }

  loadFlag = (e) => {
    this.setState({
      loadVideo: true,
    });
  }

  render(){
    return (      
      <main className="content">
        <div className="content__main content__main_horizontal js-hor-scroll">
          <MainBlock 
            key = '1'
            title = 'Онлайн запись' 
            subtitle = 'Записаться на мойку Янтарь'
            class = 'is-active'
            img = {mainBg1}
            loadVideo = {this.state.loadVideo}
            loadFlag = {this.loadFlag}
            video = {
                      this.state.items.SECTION1_VIDEOMP4 ? 
                        {
                          videoMp4: this.state.items.SECTION1_VIDEOMP4.VALUE, 
                          videoWebm: this.state.items.SECTION1_VIDEOWEBM.VALUE,
                        }
                      : ''
                    }
            >
            <AddressList />
          </MainBlock>
          
          <MainBlock
            key = '2'
            title = {this.state.items.SECTION2_TITLE ? this.state.items.SECTION2_TITLE.VALUE : ''}
            subtitle = {this.state.items.SECTION2_SUBTITLE ? this.state.items.SECTION2_SUBTITLE.VALUE : ''}
            img = {mainBg2}
            buttonText = 'Смотреть'  
            buttonto= '/services/'  
          >
            <MainBlockText text = {this.state.items.SECTION2_DESCRIPTION ? this.state.items.SECTION2_DESCRIPTION.VALUE : ''} />
          </MainBlock> 
          
          <MainBlock
            key = '3'
            title = {this.state.items.SECTION3_TITLE ? this.state.items.SECTION3_TITLE.VALUE : ''}
            subtitle = {this.state.items.SECTION3_SUBTITLE ? this.state.items.SECTION3_SUBTITLE.VALUE : ''}
            img = {mainBg3} 
            buttonText = 'Подробнее'  
            buttonto= '/about-company/'  
          >
            <TechList/>
          </MainBlock>
          
          <MainBlock
            key = '4'
            title = {this.state.items.SECTION4_TITLE ? this.state.items.SECTION4_TITLE.VALUE : ''}
            subtitle = {this.state.items.SECTION4_SUBTITLE ? this.state.items.SECTION4_SUBTITLE.VALUE : ''}
            img = {mainBg4}
            buttonText = 'Подробнее'  
            buttonto= '/app/'  
          >
            <img className="main-block__app" src={appImg} alt="" role="presentation"/>
            <Hashtags key='2' list = {this.state.items.SECTION4_LIST ? this.state.items.SECTION4_LIST.VALUE : ''} />
          </MainBlock>

          <MainBlock
            key = '5'
            title ={this.state.items.SECTION5_TITLE ? this.state.items.SECTION5_TITLE.VALUE : ''} 
            img = {mainBg5}
            buttonText = 'Смотреть'  
            buttonto= '/recommendations/'  
          >
            <MainBlockText text = {this.state.items.SECTION5_DESCRIPTION ? this.state.items.SECTION5_DESCRIPTION.VALUE : ''} />
          </MainBlock> 
          
          <MainBlock
            key = '6'
            title = {this.state.items.SECTION6_TITLE ? this.state.items.SECTION6_TITLE.VALUE : ''}
            subtitle = {this.state.items.SECTION6_SUBTITLE ? this.state.items.SECTION6_SUBTITLE.VALUE : ''}
            img = {mainBg6}
            buttonText = 'Смотреть'  
            buttonto= '/loyalty/'  
          >
            <MainBlockText text = {this.state.items.SECTION6_DESCRIPTION ? this.state.items.SECTION6_DESCRIPTION.VALUE : ''} />
          </MainBlock>

        </div>
      </main>
    );
  }
}

export default Main;
