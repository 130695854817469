import React, { Component } from 'react';

import Button from '../components/Button'

class NotFound extends Component {

  render(){
    return (      
      <main className="content">
        <div className="content__main">
          <div className="main-banner main-banner_full-screen main-banner_bgi_404">
            <div className="container main-banner__container">
              <div className="main-banner__content">
                <div className="title-404 main-banner__title-404">4<span className="title-404__zero">0</span>4</div>
                <div className="main-banner__text">
                  <p>Ошибочка подъехала!<br />Такой страницы не существует, либо она была удалена. Может, попробуете что-то другое?</p>
                </div>
                <Button className='button button_sz_md main-banner__button' to= '/'>Сдать на главную</Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default NotFound;
