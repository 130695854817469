import React from 'react';

import Button from './Button'

function TechList() {

  return (
    <div className="tech-list">
      <Button className="tech-list__item" to='/jantar-technology/'>Янтарь.<span>Technology</span></Button>
      <Button className="tech-list__item" to='/jantar-selection/'>Янтарь.<span>Selection</span></Button>
      <Button className="tech-list__item" to='/jantar-team/'>Янтарь.<span>ProTeam</span></Button>
    </div>
  );
}

export default TechList;
