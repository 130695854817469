import React, { Component } from 'react';
import {registrationContext} from '../context';
import {CSSTransition, TransitionGroup} from "react-transition-group"


import StepLink from './StepLink';

class StepInfo extends Component {

  static contextType = registrationContext;

  render(){
    
    let dataStep ={
      '/registration/step-2': {
        index: '2',
        title: 'Выберите дату и время —'
      },
      '/registration/step-3': {
        index: '3',
        title: 'Выберите услуги —'
      },
      '/registration/step-4': {
        index: '4',
        title: 'Ваши контакты'
      },
    }

    return (
      dataStep[this.context.stepLink] ?
        <div className="step-info">
          <TransitionGroup>
            <CSSTransition
              key={dataStep[this.context.stepLink].index}
              timeout={500}
              classNames="step-info"
            >
              <div className="step-info__progress">
                <div className="step-info__name">Онлайн запись</div>
                <div className="step-info__value">Шаг {dataStep[this.context.stepLink] ? dataStep[this.context.stepLink].index : ''}/4</div>
              </div>
            </CSSTransition>
          </TransitionGroup>   
          <StepLink/>
        </div>
      : ''
    );
  }
}

export default StepInfo;
