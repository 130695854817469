import React, { Component } from 'react';
import InputMask from 'react-input-mask';

class Input extends Component {

  render(){
    return (      
      <label className="input-field">
        <InputMask className="input" {...this.props} />
      </label>
    );
  }
}

export default Input;
