import React, { Component } from 'react';
import {carWahshesContext} from '../context';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import ContactInfo from '../components/ContactInfo'
import BackendApi from '../BackendApi';
import ServiceBanner from '../components/ServiceBanner'

class Contacts extends Component {

  constructor(props){
		super(props);

		this.state = {
      content: [],
    };
	}

  componentDidMount(){
    BackendApi.includedAreas('page.contacts').then((result) => {
      this.setState({
        content: result.data.result ? result.data.result:[],
      });
    }) 
  }  

  render(){

    var hashLink = window.location.hash;
    var anchorElem = document.getElementById(hashLink);
    if (anchorElem) {
      setTimeout(() => {
        var elemPos = anchorElem.offsetTop
        var elemHeight = anchorElem.offsetHeight 
        console.log(elemHeight)
        window.scrollTo({
          top: elemPos + elemHeight,
          // behavior: "smooth"
        })
      }, 1000)
    }

    return (      
      <carWahshesContext.Consumer>
        {(carwashes) => {
          return (
            <main className="content">
              <div className="content__main">
                <div className="scroll-button"></div>
                <div className="main-banner main-banner_sz_lg main-banner_bgi_contacts">
                  <div className="container main-banner__container">
                    <div className="main-banner__content">
                      <div className="main-banner__title">{this.state.content.BLOCK1_TITLE ? this.state.content.BLOCK1_TITLE.VALUE : ''}</div>
                      <div className="main-banner__text"><p>{this.state.content.BLOCK1_DESCRIPTION ? this.state.content.BLOCK1_DESCRIPTION.VALUE : ''}</p></div>
                      <AnchorLink className="button main-banner__button" href='#contacts-grid'>Подробнее</AnchorLink>
                    </div>
                  </div>
                </div>
                <div className="content-inner">
                  <div className="content-inner__placeholder">
                    <div className="container content-inner__container">
                      <div className="contacts-grid" id='contacts-grid'>
                        <div className="contacts-grid__grid">
                          <div className="contacts-grid__grid-row">
                            {carwashes.length > 0 ? carwashes.map((item, index) => (
                              <ContactInfo key = {item.ID} idContact = {item.ID} index = {index} address = {item.PROPERTY_VALUES.ADRESS} phone = {item.PROPERTY_VALUES.PHONE} mail = {item.PROPERTY_VALUES.EMAIL} geo = {item.PROPERTY_VALUES.GEO}/>
                            )):''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ServiceBanner />
              </div>
            </main>
          )
        }}
      </carWahshesContext.Consumer>
    )
  }
}

export default Contacts;
