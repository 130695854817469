import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group"

import Main from '../pages/Main';
import Registration from '../pages/Registration';
import Loyalty from '../pages/Loyalty';
import Contacts from '../pages/Contacts';
import Addresses from '../pages/Addresses';
import Recommendations from '../pages/Recommendations';
import AboutCompany from '../pages/AboutCompany';
import JantarTeam from '../pages/JantarTeam';
import JantarSelection from '../pages/JantarSelection';
import JantarTechnology from '../pages/JantarTechnology';
import AppPage from '../pages/AppPage';
import Corporate from '../pages/Corporate';
import SearchCategory from '../pages/SearchCategory';
import Services from '../pages/Services';
import NotFound from '../pages/NotFound';



  
const AnimatedSwitch = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition key={location.key} classNames="route-animation" timeout={2000}>
      <Switch location={location}>
        <Route path="/" exact component={Main} />
        <Route path="/registration/" component={Registration} />
        <Route path="/recommendations/" component={Recommendations} />
        <Route path="/loyalty/" component={Loyalty} />
        <Route path="/contacts/" component={Contacts} />
        <Route path="/addresses/" component={Addresses} />
        <Route path="/about-company/" component={AboutCompany} />
        <Route path="/jantar-team/" component={JantarTeam} />
        <Route path="/jantar-selection/" component={JantarSelection} />
        <Route path="/jantar-technology/" component={JantarTechnology} />
        <Route path="/app/" component={AppPage} />
        <Route path="/corporate/" component={Corporate} />
        <Route path="/search-category/" component={SearchCategory} />
        <Route path="/services/" component={Services} />
        <Route component={NotFound} />
      </Switch>    
    </CSSTransition>
  </TransitionGroup>
));

function RoutGroup() {
  return (
    <AnimatedSwitch />
  )
}



export default RoutGroup;
