import React from 'react';

function Hamburger(props) {
  return (
    <button className="hamburger" onClick={props.toggleMenu} aria-label="Меню">
      <span className="hamburger__icon"></span>
    </button>
  );
}

export default Hamburger;
