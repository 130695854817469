import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import WantKnow from '../components/WantKnow';
import Button from '../components/Button';
import specialAttention from '../media/img/components/special-attention/maybach.png'
import imageSection from '../media/img/components/image-section/team.jpg'
import companyCard from '../media/img/components/company-card/image-2.jpg'


class AboutCompany extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      showContent: false,
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('jantar.team').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 
  }

  showContent = () => {
    this.setState({
      showContent:!this.state.showContent,
    });
  }

  render(){
    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <div className="team-page">
            <section className="main-banner main-banner_sz_lg main-banner_bgi_404 team-page__main-banner">
              <div className="container main-banner__container">
                <div className="main-banner__content">
                  <div className="main-banner__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK1_TITLE ? this.state.items.BLOCK1_TITLE.VALUE : '' }}></div>
                  <div className="main-banner__text"><p>{this.state.items.BLOCK1_DESCRIPTION ? this.state.items.BLOCK1_DESCRIPTION.VALUE : ''}</p></div>
                  <Button className='button main-banner__button' onClick={() => this.props.history.goBack()}>Назад</Button>
                  <div className="main-banner__text-2"><p>{this.state.items.BLOCK1_CONTENT ? this.state.items.BLOCK1_CONTENT.VALUE : ''}</p></div>
                </div>
              </div>
            </section>
            <div className="image-section image-section image-section_pos_top team-page__image-section">
              <div className="image-section__image-wrap"><img className="image-section__image" src={imageSection} alt=""/></div>
            </div>
            <div className="team-page__training-wrapper">
              <section className="team-training team-page__team-training">
                <div className="container team-training__container">
                  <h2 className="team-training__title">{this.state.items.BLOCK2_TITLE ? this.state.items.BLOCK2_TITLE.VALUE : ''}</h2>
                  <div className="team-training__grid">
                    <ol className="team-training__grid-row">
                      {this.state.items.BLOCK2_ITEMTITLE ? this.state.items.BLOCK2_ITEMTITLE.VALUE.map((item, index) => (
                        <li className="team-training__grid-item">
                          <h3 className="team-training__item-title">{item}</h3>
                          <div className="team-training__item-text"><p>{this.state.items.BLOCK2_ITEMDESCRIPTION.VALUE[index]}</p></div>
                        </li>
                      )):''}
                    </ol>
                  </div>
                </div>
              </section>
              <div className="container">
                <div className="company-card team-page__company-card-1">
                  <div className="company-card__content">
                    <h2 className="company-card__title company-card__title_line" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK3_TITLE ? this.state.items.BLOCK3_TITLE.VALUE : '' }}></h2>
                    <div className="company-card__text"><p>{this.state.items.BLOCK3_DESCRIPTION ? this.state.items.BLOCK3_DESCRIPTION.VALUE : ''}</p></div>
                  </div>
                  <div className="company-card__image-col">
                    <div className="company-card__image-wrap"><img className="company-card__image" src={companyCard} alt=""/></div>
                  </div>
                </div>
              </div>
            </div>
            <section className="special-attention team-page__special-attention" style={{backgroundImage: 'url(' + specialAttention + ')'}}>
              <div className="container special-attention__container">
                <div className="special-attention__row">
                  <h2 className="special-attention__title">{this.state.items.BLOCK4_TITLE ? this.state.items.BLOCK4_TITLE.VALUE : ''}</h2>
                  <ul className="special-attention__list">
                    {this.state.items.BLOCK4_LIST ? this.state.items.BLOCK4_LIST.VALUE.map((item) => (
                      <li className="special-attention__list-item">{item}</li>
                    )):''}
                  </ul>
                </div>
              </div>
            </section>
            <section className="tape-section tape-section_reverse team-page__tape-section">
              <div className="container tape-section__container">
                <div className="tape-section__content">
                  <h2 className="tape-section__title">{this.state.items.BLOCK5_TITLE ? this.state.items.BLOCK5_TITLE.VALUE : ''}</h2>
                  <div className="tape-section__text" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK5_DESCRIPTION ? this.state.items.BLOCK5_DESCRIPTION.VALUE : '' }}></div>
                </div>
              </div>
            </section>
            <section className="candidate-examination team-page__candidate-examination">
              <div className="container candidate-examination__container">
                <h2 className="candidate-examination__title">{this.state.items.BLOCK6_TITLE ? this.state.items.BLOCK6_TITLE.VALUE : ''}</h2>
                <div className="candidate-examination__grid">
                  <ul className="candidate-examination__grid-row">
                    {this.state.items.BLOCK6_LIST ? this.state.items.BLOCK6_LIST.VALUE.map((item, index) => (
                      <li className="candidate-examination__grid-item">
                        <span className="candidate-examination__item-icon" style={{backgroundImage: 'url(' + this.state.items.BLOCK6_ICONS.VALUE[index] + ')'}}></span> {item}</li>
                    )):''}
                  </ul>
                </div>
              </div>
            </section>
            <section className={"list-section list-section_icons_logo team-page__list-section-1 " + (this.state.showContent ? 'active' : '')} show-block="">
              <div className="container list-section__container">
                <h2 className="list-section__title">{this.state.items.BLOCK7_TITLE ? this.state.items.BLOCK7_TITLE.VALUE : ''}</h2>
                <div className="list-section__grid">
                  <ul className="list-section__grid-row">
                    {this.state.items.BLOCK7_LIST ? this.state.items.BLOCK7_LIST.VALUE.map((item) => (
                      <li className="list-section__grid-item">{item}</li>
                    )):''}
                  </ul>
                </div>
                <button className="button-2 button-2_theme_gray list-section__show-btn" onClick={this.showContent}>Показать еще</button>
              </div>
            </section>
            <div className="team-page__individual-wrapper">
              <section className="list-section list-section_theme_dark list-section_icons_logo team-page__list-section-2">
                <div className="container list-section__container">
                  <h2 className="list-section__title">{this.state.items.BLOCK8_TITLE ? this.state.items.BLOCK8_TITLE.VALUE : ''}</h2>
                  <div className="list-section__grid">
                    <ul className="list-section__grid-row">
                      {this.state.items.BLOCK8_LIST ? this.state.items.BLOCK8_LIST.VALUE.map((item) => (
                        <li className="list-section__grid-item">{item}</li>
                      )):''}
                    </ul>
                  </div>
                </div>
              </section>
              <div className="container">
                <div className="company-card team-page__company-card-2">
                  <div className="company-card__content">
                    <p className="company-card__subtitle">{this.state.items.BLOCK9_SUBTITLE ? this.state.items.BLOCK9_SUBTITLE.VALUE : ''}</p>
                    <h2 className="company-card__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK9_TITLE ? this.state.items.BLOCK9_TITLE.VALUE : '' }}></h2>
                    <div className="company-card__text company-card__text_fz_sm"><p>{this.state.items.BLOCK9_DESCRIPTION ? this.state.items.BLOCK9_DESCRIPTION.VALUE : ''}</p></div>
                  </div>
                  <div className="company-card__image-col">
                    <div className="company-card__image-wrap"><img className="company-card__image" src={companyCard} alt=""/></div>
                  </div>
                </div>
              </div>
            </div>
            <WantKnow />
          </div>
        </div>
      </main>
    );
  }
}

export default AboutCompany;
