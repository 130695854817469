import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from './Button'
import Input from './Input'
import preloader from '../media/preloader.svg'


class CallForm extends Component {

  constructor(props){
		super(props);

		this.state = {
      submited: false,
      loading: false,
      name: '',
      phone: '',
      fieldErrors: {},
      openForm: false,
    };
  }

  handleInputChange = field => e => {
    let {value} = e.target;

		let fieldErrors = {...this.state.fieldErrors};
    if(fieldErrors[field]) delete fieldErrors[field];
    
		this.setState({
			[field]: value,
			fieldErrors
		})
	};
  
  handleFormSubmit = e => {
    e.preventDefault();
    const {name, phone} = this.state;
    let emptyField = false;
    let fieldList = {...this.state.fieldErrors};

    for(var key in this.state){
      if(this.state[key].length <= 0) {
        emptyField = true;        
        Object.assign(fieldList, {[key]: 'Заполните поле'});
      }
    }
    
    this.setState({
      fieldErrors: fieldList,
    })

    if(!emptyField) {

      this.setState({loading: true});
      BackendApi.callForm(name, phone).then((result) => {
        switch(result.status)
        {
          case 200:
            this.setState({submited: 'Ваша заявка отправлена'});
            setTimeout(() => {
              this.setState({openForm: false})
            }, 1000);
            break;

          case 500:
            this.setState({submited: 'Произошла непредвиденная ошибка, попробуйте снова'});
            break;
          
            default:
              const mapApiFields = {
                'PHONE': 'phone',
              };

              let fieldErrors = {};

              for(let key in mapApiFields) {
                if(key === result.data.argument) {
                  fieldErrors = {
                    [mapApiFields[key]]: result.data.error_description,
                  }
                }
              }

              this.setState({
                fieldErrors
              })
          };

        this.setState({loading: false});
      })
    }
  }

  getInputClassError = field => {
		return !this.isValidField(field) ? 'error':'';
  };
  
  isValidField(field) {
    const {fieldErrors} = this.state;
		return !fieldErrors[field];
  }

  returnRequest = e => {
    e.preventDefault();
    this.setState({
      submited: false
    })
  }
  
  openForm = e => {
    e.preventDefault();
    this.setState({
      openForm: true
    })
  }
  
  closeForm = e => {
    e.preventDefault();
    this.setState({
      openForm: false
    })
  }

  render(){
    return (      
      <div className={"call-form " + (this.state.openForm ? 'is-active' : '')}>
        <button className="call-form__open-button" onClick={this.openForm}> <span>Заказать консультацию</span>
        </button>
        <button className="call-form__button-close" onClick={this.closeForm}></button>
        <div className="call-form__body">
          <div className="call-form__title">{!this.state.submited ? 'Заказать консультацию' : this.state.submited}</div>
          <form className="form" onSubmit={this.handleFormSubmit}>
            <div className="form__body" style={this.state.submited ? {visibility: 'hidden'} : {visibility: 'visible'}}>
              <div className="form__item">
                <Input className={'input ' + this.getInputClassError('name')} type='text' placeholder='Имя' name='name' onChange={this.handleInputChange('name')}/>
              </div>
              <div className="form__item">
                <Input className={'input ' + this.getInputClassError('phone')} type='tel' placeholder='Номер телефон' name='phone' onChange={this.handleInputChange('phone')} mask="+7 (999) 999 99 99"/>
              </div>
            </div>
            <div className="form__footer">
              {!this.state.submited ?
                <Button className='button button_full button_sz_sm' type="submit" style={{position:'relative'}}>{!this.state.loading ? 'Отправить' : <img src={preloader} style={{height:'40px', margin:'-13px 0'}} alt=''/>}</Button>
                : <button className="button button_full button_sz_sm" onClick={this.returnRequest} type="submit">Хорошо</button>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CallForm;
