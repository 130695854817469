import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import {registrationContext} from '../context';

import StepRoute from '../components/StepRoute'
import StepInfo from '../components/StepInfo'
import StepTitle from '../components/StepTitle'

import mainBanner from '../media/img/components/main-block/bg-7.jpg'



class Registration extends Component {

  constructor(props){
		super(props);

		this.state = {
      carWasherId: '',
      date: '',
      time: '',
      timeList: [],
      servicesId: [],
      serviceName: '',
      formData: [],
      stepLink: '',
      checkWasher: this.checkWasher,
      checkDate: this.checkDate,
      emptyTimeList: this.emptyTimeList,
      checkTime: this.checkTime,
      checkServices: this.checkServices,
      stepNumber: this.stepNumber,
    };
  }

  
  
  checkWasher = (washerId) => {
    this.setState({
      carWasherId: washerId[0],
      time: '',
      servicesId: [],
      serviceName: '',
    });
  }
  
  stepNumber = (number) => {
    this.setState({
      stepLink: number,
    });
  }

  emptyTimeList = (list) => {
    this.setState({
      timeList: list,
    });
  }
  
  checkDate = (date) => {
    if(date.getDate() !== new Date().getDate()) {
      date.setHours(0); 
      date.setMinutes(0);
    } else {
      date = new Date()
    }
    this.setState({
      date: date,
      time: '',
    });
  }
  
  checkTime = (time) => {
    this.setState({
      time,
      servicesId: [],
      serviceName: '',
    });
  }

  checkServices = (servicesId, serviceName) => {
    this.setState({
      servicesId,
      serviceName
    });
  }
  

  render(){
    return (      
      <registrationContext.Provider value={this.state}>
        <main className="content content_reg">
          <div className="content__main">
            <section className="sing-up" style={{backgroundImage: 'url(' + mainBanner + ')'}}>
              <div className="sing-up__wrapper">
                <Router>
                  <div className="sing-up__step is-active">
                    <StepInfo/>
                    <div className="sing-up__content">
                      <StepTitle/>
                      <div className="sing-up__body" style={{position:'relative'}}>
                        <StepRoute/>
                      </div>
                    </div>
                  </div>
                </Router>
              </div>
            </section>
          </div>
        </main>
      </registrationContext.Provider>
    );
  }
}

export default Registration;
