import React, { Component } from 'react';


import HeadDashboard from './HeadDashboard';
import MobileMenu from './MobileMenu';

class Header extends Component {

  constructor(props){
		super(props);

		this.state = {
      openMenu: false,
    };
  }
  
  toggleMenu = (e) => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  }
  closeMenu = (e) => {
    this.setState({
      openMenu: false,
    });
  }
 
  render(){
    return (
      <header className="header">
        <HeadDashboard closeMenu={this.closeMenu} toggleMenu={this.toggleMenu} />
        <MobileMenu closeMenu={this.closeMenu} openMenu={this.state.openMenu} />
      </header>
    );
  }
}

export default Header;
