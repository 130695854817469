import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from './Button'

class ProgramRecomendation extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('program.recomendation').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 
  }

  render(){
    return (      
      <section className="main-banner main-banner_bgi_recomendations-2 technology-page__main-banner-2">
        <div className="container main-banner__container">
          <div className="main-banner__content">
            <div className="main-banner__title" dangerouslySetInnerHTML={{ __html: this.state.items.TITLE ? this.state.items.TITLE.VALUE : '' }}></div>
            <div className="main-banner__text"><p>{this.state.items.DESCRIPTION ? this.state.items.DESCRIPTION.VALUE : ''}</p></div>
            <Button className='button button_sz_md main-banner__button' to= '/recommendations/'>Смотреть</Button>
          </div>
        </div>
      </section>
    );
  }
}

export default ProgramRecomendation;
