import React, { Component } from 'react';

import ContactSlider from './ContactSlider'
import Button from './Button'

class ContactInfoSlider extends Component {


  render(){

    let anchorId = {
      '1': '#voennay',
      '2': '#bolshev',
      '3': '#energy',
    }

    function endings(number) {
      let day;
      switch (+number) {
        case 1 :
          day = 'бокс'
          break;
        case 4 :
          day = 'бокса'
          break;
        case 6 :
          day = 'боксов'
          break;
        default:
          day = 'боксов';
      }
      return day      
    }

    return (      
      <div className="contact-info contacts-grid__grid-item" id={anchorId[this.props.idContact]}>
        <div className="contact-info__picture">
          <ContactSlider imgList = {this.props.photo}/>
        </div>
        <div className="contact-info__content">
          <div className="contact-info__header contact-info__item">
            <h3 className="contact-info__title">{this.props.address}</h3>
            <div className="tags-list contact-info__tags-list">
              <div className="tags-list__grid">
                <ul className="tags-list__grid-row">
                  <li className="tags-list__grid-item">{this.props.boxes + ' ' + endings(this.props.boxes)}</li>
                  <li className="tags-list__grid-item">Круглосуточно</li>
                  <li className="tags-list__grid-item">Все услуги</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="contact-info__text contact-info__item">
            <ul>
              <li>{this.props.description}</li>
            </ul>
          </div>
          <div className="contact-info__buttons contact-info__item">
            <div className="contact-info__buttons-row">
              <Button className='contact-info__buttons-item button button_fz_sm' to= '/'>Записаться</Button>
              <Button className='contact-info__buttons-item button-2 button-2_fz_sm' to= {'/contacts' + anchorId[this.props.idContact]}>На карте</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfoSlider;
