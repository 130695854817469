import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Article from './Article'

class NewsGrid extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: []
    };
	}

	componentDidMount(){
		BackendApi.iblockInfoSortInvert(3).then((result) => {
			this.setState({
				items: Array.isArray(result.data.result) ? result.data.result:[],
      });
    })    
  }

  render(){
    return (      
      <div className="articles-grid news-section__grid">
        <div className="articles-grid__grid">
          <div className="articles-grid__grid-row"> 
            {this.state.items.length > 0 ? this.state.items.map((item) => (
              <Article key={item.ID} date={item.PROPERTY_VALUES.DATE_CARD} title={item.NAME} description={item.PREVIEW_TEXT}  type={item.PROPERTY_VALUES.TYPE_CARD}/>
            )):''}
          </div>
        </div>
      </div>
    );
  }
}

export default NewsGrid;
