import React, { Component } from 'react';
import {registrationContext} from '../../context';

// import Button from '../Button'

class Step5Success extends Component {

  static contextType = registrationContext;

  componentDidMount(){
    this.context.stepNumber(this.props.location.pathname)
  }

  render(){
    return (   
      <div className="sing-up__footer">
        <div className="sing-up__footer-wrap">
          <a className='button' href='/'>На главную</a>
        </div>
      </div>
    );
  }
}

export default Step5Success;
