import React, { Component } from 'react';
import {registrationContext} from '../context';
import {CSSTransition, TransitionGroup} from "react-transition-group"


class StepTitle extends Component {

  static contextType = registrationContext;

  render(){
    
    let dataStep ={
      '/registration/': {
        index: '1',
        title: 'Выберите адрес <br /> мойки для записи',
        subtitle: 'Онлайн запись'
      }, 
      '/registration/step-1': {
        index: '1',
        title: 'Выберите адрес <br /> мойки для записи',
        subtitle: 'Онлайн запись'
      }, 
      '/registration/step-2': {
        index: '2',
        title: 'Выберите дату и время'
      },
      '/registration/step-3': {
        index: '3',
        title: 'Выберите услуги'
      },
      '/registration/step-4': {
        index: '4',
        title: 'Ваши контакты'
      },
      '/registration/step-5': {
        index: '5',
        title: 'Ваша запись добавлена',
        suptitle: 'Ждите смс подтверждения',
      },
      '/registration/step-5-failed': {
        index: '5',
        title: 'Что-то пошло не так',
        suptitle: 'Мы приносим извинения, но заявка не ушла, повторите запись или звоните нам',
      },
    }

    return (
      this.context.stepLink ?
        <TransitionGroup>
          <CSSTransition
            key={dataStep[this.context.stepLink] ? dataStep[this.context.stepLink].index : 1}
            timeout={500}
            classNames="title-animate"
          >
            <div className="sing-up__head">
              {dataStep[this.context.stepLink] ? <div className="sing-up__subtitle">{dataStep[this.context.stepLink].subtitle}</div> : ''}
              <div className="sing-up__title" dangerouslySetInnerHTML={{ __html:dataStep[this.context.stepLink] ? dataStep[this.context.stepLink].title : ''}}></div>
              {dataStep[this.context.stepLink] ? <div className="sing-up__suptitle">{dataStep[this.context.stepLink].suptitle}</div> : ''}
            </div>
          </CSSTransition>
        </TransitionGroup>        
      : ''
    );
  }
}

export default StepTitle;
