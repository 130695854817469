import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from '../components/Button'

class ServiceBanner extends Component {

  constructor(props){
		super(props);

		this.state = {
      content: [],
    };
	}

  componentDidMount(){
    BackendApi.includedAreas('block.service').then((result) => {
      this.setState({
        content: result.data.result ? result.data.result:[],
      });
    }) 
  }  

  render(){
    return (
      <div className="main-banner main-banner_bgi_service-overview">
        <div className="container main-banner__container">
          <div className="main-banner__content">
            <div className="main-banner__title" dangerouslySetInnerHTML={{ __html: this.state.content.TITLE ? this.state.content.TITLE.VALUE : '' }}></div>
            <div className="main-banner__text" dangerouslySetInnerHTML={{ __html: this.state.content.DESCRIPTION ? this.state.content.DESCRIPTION.VALUE : '' }}></div>
            <Button className='button main-banner__button' to= '/services/'>Смотреть</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceBanner;
