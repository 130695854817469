import {backedUrl} from './config';
import {stringify} from 'qs';
// import CookieStor from "./CookieStor";
// import oToFd from 'object-to-formdata';

// const MAX_REFRESH_TOKEN_REQUEST = 1;
// let refreshTokenRequestCount = 0;

class BackendApi {

	/**
	 * @param {string} url
	 * @param {{method?: string, headers?: object, body?: string|object|FormData}} [options]
	 * @return {Promise}
	 */
	static sendRequest(url, options){
		// const self = this;
		options = options || {};
		let method = options.method || 'GET';
		let headers = options.headers || {'Accept': 'application/json'};
		let body = options.body || null;
		let requestUrl = url;

		if(method === 'POST' || body)
		{
			if(!(body instanceof FormData))
			{
				headers['Content-Type'] = 'application/x-www-form-urlencoded';
			}
			method = 'POST';
		}

		if(body && typeof body === 'object' && !(body instanceof FormData))
		{
			body = stringify(body);
		}

		return new Promise((resolve, reject) => {
			fetch(requestUrl, {
				method,
				headers,
				body,
			})
				.then((response) => {
					response.json().then((data) => {
						let resultData = {
							data: data,
							status: response.status,
							statusText: response.statusText
						};

						let pagination = {};
						const xPaginationKeyToVal = {
							'X-Pagination-Current-Page': 'currentPage',
							'X-Pagination-Page-Count': 'pageCount',
							'X-Pagination-Per-Page': 'perPage',
							'X-Pagination-Total-Count': 'totalCount',
						};
						for(let xPaginationKeyToValKey in xPaginationKeyToVal)
						{
							const value = response.headers.get(xPaginationKeyToValKey);
							if(value !== undefined || value !== null)
							{
								pagination[xPaginationKeyToVal[xPaginationKeyToValKey]] = value;
							}
						}
						if(Object.keys(pagination).length)
						{
							resultData.pagination = pagination;
						}

						resolve(resultData);
					})
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static async carwashList(){
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.carwash.list')
	}

	static async programList(){
		let cacheKey = 'f9a26ab3b0953870f2102fbff4575386';
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.program.list?' + cacheKey)
	}
	
	static async serviceListApp(){
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.service.services?filter[ACTIVE_ON_APP]=1')
	}
	
	static async serviceListSite(){
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.service.services?filter[ACTIVE_ON_SITE]=1')
	}
	
	static async iblockInfo(iblockId, elementId){
		let param;
		if (elementId) {
			param = '?ENTITY=' + iblockId + '&FILTER[ID]=' + elementId
		} else {
			param = '?ENTITY=' + iblockId
		}
		
		return await this.sendRequest(backedUrl + '/rest/mhmrestiblock.item.get' + param)
	}
	
	static async iblockInfoSort(iblockId, elementId){
		let param;
		if (elementId) {
			param = '?ENTITY=' + iblockId + '&FILTER[ID]=' + elementId
		} else {
			param = '?ENTITY=' + iblockId + '&SORT[SORT]=ASC'
		}
		
		return await this.sendRequest(backedUrl + '/rest/mhmrestiblock.item.get' + param)
	}
	
	static async iblockInfoSortInvert(iblockId, elementId){
		let param;
		if (elementId) {
			param = '?ENTITY=' + iblockId + '&FILTER[ID]=' + elementId
		} else {
			param = '?ENTITY=' + iblockId + '&SORT[SORT]=DESC'
		}
		
		return await this.sendRequest(backedUrl + '/rest/mhmrestiblock.item.get' + param)
	}
	
	static async includedAreas(code){
		return await this.sendRequest(backedUrl + '/rest/mhmrestiblock.mhmarea.get?CODE=' + code)
	}
	
	static async getBrand(value){
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.car.marks?filter[name]=' + value + '%')
	}
	
	static async getModel(model, barndId){
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.car.models?filter[name]=' + model + '%&filter[car_mark_id]=' + barndId)
	}
	
	static async getCategoryList(){
		return await this.sendRequest(backedUrl + '/rest/mhmerpcarwash.car.categories')
	}

	static async emptyTime(date, carwash_id) {
		return await this.sendRequest(backedUrl + "/rest/mhmerpcarwash.site.order.find_free_reserving", {
			body: {
				date: date,
				carwash_id: carwash_id,
			}
		});
	}
	
	static async corporateForm(company, count, email, fio, phone) {
		return await this.sendRequest(backedUrl + "/local/tools/forms_rest/", {
			body: {
				action: 'partners_service',
				COMPANY_NAME: company,
				CAR_COUNT: count,
				EMAIL: email,
				FIO: fio,
				PHONE: phone,
			}
		});
	}
	
	static async orderForm(formData) {
		return await this.sendRequest(backedUrl + "/rest/mhmerpcarwash.site.order.add", {
			body: {
				carwash_id: formData.carWashId,
				date_start: formData.startDate,
				service_id: formData.serviceList,
				car_number: formData.carNumber,
				car_category_id: 1,
				client_phone: formData.phone,
				client_name: formData.name,
				save: 1,
			}
		});
	}
	static async callForm(name, phone) {
		return await this.sendRequest(backedUrl + "/local/tools/forms_rest/", {
			body: {
				action: 'callback',
				NAME: name,
				PHONE: phone,
			}
		});
	}

}

export default BackendApi;