import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from './Button'

class WantKnow extends Component {

  constructor(props){
		super(props);

		this.state = {

      items: [],
    };
	}

	componentDidMount(){
		BackendApi.iblockInfo(8).then((result) => {
			this.setState({
				items: Array.isArray(result.data.result) ? result.data.result:[],
      });
    })
  }

  render(){
    let linkList = {
      system_technology: '/jantar-technology/',
      system_selection: '/jantar-selection/',
      system_proteam: '/jantar-team/'
    }
    return (      
      <section className="want-know about-page__want-know">
        <div className="container want-know__container">
          <h2 className="want-know__title">Хотите узнать о нас больше?</h2>
          <div className="want-know__grid">
            <div className="want-know__grid-row">
              {this.state.items.length > 0 ? this.state.items.map((item) => (
                <div className="want-know-item want-know__grid-item">
                  <h3 className="want-know-item__title" dangerouslySetInnerHTML={{ __html: item.NAME}}></h3>
                  <div className="want-know-item__text" dangerouslySetInnerHTML={{ __html: item.PREVIEW_TEXT}}></div>
                  <Button className='button button_fz_md want-know-item__button' to={linkList[item.CODE]}>Изучить</Button>
                </div>
              )):''}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WantKnow;
