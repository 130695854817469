import React, { Component } from 'react';
import Slider from "react-slick";

class ContactSlider extends Component {
  render(){

    const settings = {
      arrows: false,
      dots: true,
      className: 'contact-slider__init',
      appendDots:  dots => (<div><ul className='slick-dots'>{dots}</ul></div>),
      dotsClass:  'contact-slider__dots slick-dots-1',
    };

    function countImg(props) {
      if (Array.isArray(props.imgList)) {
        return (
          props.imgList.map((item) => (
            <Slider {...settings}>
              <div className="contact-slider__slide">
                <div className="contact-slider__image-wrap">
                  <img className="contact-slider__image" src={item} alt=""/>
                </div>
              </div>
            </Slider>
          ))
        )
      } else {
        return (
          <div className="contact-slider__slide">
            <div className="contact-slider__image-wrap">
              <img className="contact-slider__image" src={props.imgList} alt=""/>
            </div>
          </div>
        )
      }
    }

    return (
      <div className="contact-slider contact-info__slider">
        <div className="contact-slider__dots slick-dots-1"></div>
          {countImg(this.props)}
      </div>
    );
  }
}

export default ContactSlider;
 