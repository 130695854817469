import React from 'react';
import $ from 'jquery'

import MobileDetect from 'mobile-detect'
import Cookies from 'js-cookie-with-file-support'
import logo from '../media/img/app-logo.png'

function AppWindows(props) {
  var windowApp;
  var md = new MobileDetect(window.navigator.userAgent);
  var os = md.os();
  console.log(os)

	if(Cookies.get('APP_DOWNLOAD_CLOSE') !== '1')
	{
		$(document).on('click', '.app-window__close-btn', function(){
			$(this).closest('.app-window').slideUp();
			Cookies.set('APP_DOWNLOAD_CLOSE', '1', {expires: 1, path: '/'});
		});

		if(os === 'AndroidOS' || os === 'iOS')
		{
			windowApp = <div class="app-window">
			<div class="container app-window__container">
				<button class="app-window__close-btn" aria-label='Закрыть окно'></button>
				<div class="app-window__logo-wrap">
					<img src={logo} alt="" class="app-window__logo-image" />
				</div>
				<div class="app-window__content">
					<div class="app-window__title">ЯНТАРЬ</div>
					<div class="app-window__subtitle">Уход в режиме Online</div>
					<div class="app-window__subtitle2">Приложение</div>
				</div>
				<a href="http://onelink.to/y48yfx" class="app-window__download-link">Перейти</a>
			</div>
		</div>;
		}
  }

  
  
  return (
    <div>{windowApp}</div>
  );
}

export default AppWindows;
