import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import BackendApi from '../BackendApi';
import ProgramGrid from '../components/ProgramGrid'
import RecomendationsList from '../components/RecomendationsList'

class Recommendations extends Component {

  constructor(props){
		super(props);

		this.state = {
			loading: true,
      items: [],
      content: [],
    };
	}

	componentDidMount(){
    BackendApi.includedAreas('page.recomendation').then((result) => {
      this.setState({
        content: result.data.result ? result.data.result:[],
      });
    }) 

		BackendApi.programList().then((result) => {
			this.setState({
        loading: false,
				items: Array.isArray(result.data.result.list) ? result.data.result.list:[],
      });
    })    
  }

  render(){
    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <div className="main-banner main-banner_sz_lg main-banner_bgi_recomendations">
            <div className="container main-banner__container">
              <div className="main-banner__content">
                <p className="main-banner__subtitle">{this.state.content.BLOCK1_SUBTITLE ? this.state.content.BLOCK1_SUBTITLE.VALUE : ''}</p>
                <h1 className="main-banner__title">{this.state.content.BLOCK1_TITLE ? this.state.content.BLOCK1_TITLE.VALUE : ''}</h1>
                <div className="main-banner__text">
                  <p>{this.state.content.BLOCK1_DESCRIPTION ? this.state.content.BLOCK1_DESCRIPTION.VALUE : ''}</p>
                </div>
                <div className="main-banner__title-2">Как часто вы моете машину?</div>
                <div className="main-banner__buttons">
                  <div className="main-banner__buttons-box">
                    {this.state.items.length > 0 ? this.state.items.map((item, index) => (
                      <AnchorLink className="button button_fz_sm button_cl_black main-banner__buttons-item" href={"#care-section-" +  index}>{item.INTENSITY_DESCRIPTION}</AnchorLink>
                    )):''}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-inner">
            <div className="content-inner__placeholder">
              <div className="container content-inner__container">
                <div className="care-grid">
                  {this.state.items.length > 0 ? this.state.items.map((item, index) => (
                    <div className="care-section care-grid__section" id={"care-section-" + index}>
                      <h2 className="care-section__title">{item.NAME},<br /><span className="care-section__subtitle">{item.INTENSITY_DESCRIPTION}</span></h2>
                      <div className="care-section__body">
                        <div className="care-subsection care-section__subsection">
                          <h3 className="care-subsection__title">Состав программы</h3>
                          <ProgramGrid key = {item.id} list = {item.goals}/>
                        </div>
                        <div className="care-subsection care-section__subsection">
                          <h3 className="care-subsection__title">Основные рекомендации</h3>
                          <RecomendationsList key = {item.id} list = {item.basic_recommendations} />
                        </div>
                        <div className="care-subsection care-section__subsection">
                          <h3 className="care-subsection__title">Технические рекомендации</h3>
                          <RecomendationsList key = {item.id} list = {item.technical_recommendations}/>
                        </div>
                      </div>
                    </div>
                  )):''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
              
    );
  }
}

export default Recommendations;
