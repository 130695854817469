import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from '../components/Button'
import ProgramRecomendation from '../components/ProgramRecomendation';
import WantKnow from '../components/WantKnow';


class JantarTechnology extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      showContent: false,
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('jantar.tehnology').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 
  }

  showContent = () => {
    this.setState({
      showContent:!this.state.showContent,
    });
  }

  render(){

    function systemList(stateData) {
      let i = 0;
      let blockContent = [];
      for (var key in stateData) {
        if(stateData['BLOCK3_LISTTITLE' + (i+1)]) {
          blockContent.push(
            <li className="care-step care-system__grid-item">
              <h3 className="care-step__title">{stateData["BLOCK3_LISTTITLE" + (i+1)].VALUE}</h3>
              <div className="care-step__grid">
                <ul className="care-step__grid-row">
                  {stateData["BLOCK3_ITEMIMG" + (i+1)].VALUE.map((item, index) => (
                    <li className="care-service care-step__grid-item">
                      <div className="care-service__image-wrap">
                        <img className="care-service__image" src={item} alt=""/>
                      </div>
                      <div className="care-service__content">
                        <div className="care-service__title">{stateData["BLOCK3_ITEMTEXT" + (i+1)].VALUE[index]}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )
        }
        i++;
      }
      return blockContent

    }
    
    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <div className="technology-page">
            <section className="main-banner main-banner_sz_lg main-banner_bgi_404 technology-page__main-banner-1">
              <div className="container main-banner__container">
                <div className="main-banner__content">
                  <div className="main-banner__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK1_TITLE ? this.state.items.BLOCK1_TITLE.VALUE : '' }}></div>
                  <div className="main-banner__text"><p>{this.state.items.BLOCK1_DESCRIPTION ? this.state.items.BLOCK1_DESCRIPTION.VALUE : ''}</p></div>
                  <Button className='button button_sz_md main-banner__button' onClick={() => this.props.history.goBack()}>Назад</Button>
                  <div className="main-banner__text-2"><p>{this.state.items.BLOCK1_CONTENT ? this.state.items.BLOCK1_CONTENT.VALUE : ''}</p></div>
                </div>
              </div>
            </section>
            <section className={"list-section technology-page__list-section " + (this.state.showContent ? 'active' : '')} show-block="">
              <div className="container list-section__container">
                <h2 className="list-section__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK2_TITLE ? this.state.items.BLOCK2_TITLE.VALUE : '' }}></h2>
                <div className="list-section__grid">
                  <dl className="list-section__grid-row">
                    {this.state.items.BLOCK2_ITEMTITLE ? this.state.items.BLOCK2_ITEMTITLE.VALUE.map((item, index) => (
                      <div className="list-section__grid-item">
                        <dt className="list-section__item-title">{item}</dt>
                        <dd className="list-section__item-text">{this.state.items.BLOCK2_ITEMDESCRIPTION.VALUE[index]}</dd>
                      </div>
                    )):''}
                  </dl>
                </div>
                <button className="button-2 button-2_theme_gray list-section__show-btn" onClick={this.showContent}>Показать еще</button>
              </div>
            </section>
            <section className="care-system technology-page__care-system">
              <div className="container care-system__container">
                <div className="care-system__header">
                  <h2 className="care-system__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK3_TITLE ? this.state.items.BLOCK3_TITLE.VALUE : '' }}></h2>
                  <div className="care-system__text">{this.state.items.BLOCK3_DESCRIPTION ? this.state.items.BLOCK3_DESCRIPTION.VALUE : ''}</div>
                </div>
                <div className="care-system__grid">
                  <ol className="care-system__grid-row">
                    {systemList(this.state.items)}
                  </ol>
                </div>
              </div>
            </section>
            <ProgramRecomendation />
            <WantKnow />
          </div>
        </div>
      </main>
    );
  }
}

export default JantarTechnology;
