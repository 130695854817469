import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from './Button'

import catImg1 from '../media/img/components/price-category/image-1.svg'
import catImg2 from '../media/img/components/price-category/image-2.svg'
import catImg3 from '../media/img/components/price-category/image-3.svg'
import catImg4 from '../media/img/components/price-category/image-4.svg'

import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin'
import 'jquery-mousewheel'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'

class SideService extends Component {

	constructor(props) {
		super(props);

		this.state = {
			items: [],
		};

	}

	componentDidMount() {
		$('[scrollbar]').mCustomScrollbar({
			theme: "default",
			scrollInertia: 0,
			mouseWheelPixels: 100
		});
	}

	componentDidUpdate(prevProps){

		var scrollWidth = window.innerWidth - document.documentElement.clientWidth;

		if (scrollWidth > 0 && window.screen.width > 1024) {
			$('<style type="text/css">').html('.overlay { margin-right: ' + scrollWidth + 'px; }').appendTo('head');
		}

		if (this.props.serviceId !== prevProps.serviceId) {
			BackendApi.iblockInfo(2, this.props.serviceId).then((result) => {
				if(Array.isArray(result.data.result)) {
					if(result.data.result.length > 0) {
						this.setState({
							items: result.data.result[0],
						});
						setTimeout( () => {
							this.props.openSide()
						}, 200)
					} else {
						this.props.closeSide()
						this.setState({
							items: [],
						});
					}
				} else {
					this.props.closeSide()
					this.setState({
						items: [],
					});
				}
			})
		}
  }

	render() {

		function catImg(index){
			var listImg = [catImg1, catImg2, catImg3, catImg4]
			return listImg[index]
		}

		return (
			<div className={"side-window page__service-detail " + (this.props.showContent ? 'active' : '')} scrollbar="">
				<button className="side-window__close-btn" aria-label="Закрыть" onClick={this.props.closeSide}></button>
				<div className="side-window__body">
					<div className="service-detail">
						<div className="service-detail__section">
							{this.state.items.DETAIL_PICTURE ? 
								<div className="service-detail__image-wrap">
									<img className="service-detail__image" src={this.state.items.DETAIL_PICTURE} alt="" />
								</div>
							: ''} 
							<h2 className="service-detail__title">{this.state.items.NAME ? this.state.items.NAME : ''}</h2>
							{this.state.items.PROPERTY_VALUES ? <p className="service-detail__subtitle">{this.state.items.PROPERTY_VALUES.CAPTION}</p> : ''}
							
						</div>
						<div className="service-detail__section">
							<div className="price-category-section">
								<div className="price-category-section__header">
									<h3 className="price-category-section__title">Цена</h3>
									<Button className='price-category-section__link' to= '/search-category/'>Какая у меня категория?</Button>
								</div>
								<div className="price-category-section__grid">
									<div className="price-category-section__grid-row">
										{this.state.items.PROPERTY_VALUES ? this.state.items.PROPERTY_VALUES.PRICE_VALUE.map((item, index) => (
											<div className="price-category price-category-section__grid-item">
												<div className="price-category__image-wrap"><img className="price-category__image" src={catImg(index)} alt="" /></div>
												<div className="price-category__content">
													<div className="price-category__title">{index + 1} категория</div>
													<div className="price-category__price">{item} р</div>
												</div>
											</div>
										)):''}
									</div>
								</div>
							</div>
						</div>
						<div className="service-detail__section">
							<div className="user-typography" dangerouslySetInnerHTML={{ __html: this.state.items.DETAIL_TEXT ? this.state.items.DETAIL_TEXT : ''}}></div>
						</div>
						<div className="service-detail__section">
							<div className="user-typography" dangerouslySetInnerHTML={{ __html: this.state.items.PREVIEW_TEXT ? this.state.items.PREVIEW_TEXT : ''}}></div>
							<Button className='button button_sz_md service-detail__submit-btn' to= '/registration/'>Онлайн запись</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SideService;
