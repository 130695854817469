import React from 'react';
import { Route, Switch, withRouter} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group"

import Step1 from './registration-steps/Step1'
import Step2 from './registration-steps/Step2'
import Step3 from './registration-steps/Step3'
import Step4 from './registration-steps/Step4'
import Step4Info from './registration-steps/Step4Info'
import Step5Success from './registration-steps/Step5Success'
import Step5Failed from './registration-steps/Step5Failed'


const AnimatedSwitch = withRouter(({ history }) => (
  <TransitionGroup >
    <CSSTransition key={history.location.key} classNames="step-animation" timeout={800}>
      <Switch location={history.location}>
        <Route path="/registration/" exact component={Step1} />
        <Route path="/registration/step-1" component={Step1} />
        <Route path="/registration/step-2" component={Step2} />
        <Route path="/registration/step-3" component={Step3} />
        <Route path="/registration/step-4" component={Step4} />
        <Route path="/registration/step-4-info" component={Step4Info} />
        <Route path="/registration/step-5" component={Step5Success} />
        <Route path="/registration/step-5-failed" component={Step5Failed} />
        <Route component={Step1} />
      </Switch>  
    </CSSTransition>
  </TransitionGroup>
));

function StepRoute() {
  return (
    <AnimatedSwitch />
  )
}



export default StepRoute;
