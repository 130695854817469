import React, { Component } from 'react';
import {registrationContext} from '../../context';

import AddressListReg from '../AddressListReg'

class Step1 extends Component {

  static contextType = registrationContext;

  componentDidMount(){
    this.context.stepNumber(this.props.location.pathname)
    setTimeout( () => {
      this.context.checkTime('')
    }, 1000)
  }

  render(){
    return (      
      <AddressListReg />
    );
  }
}

export default Step1;
