import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {carWahshesContext, registrationContext} from '../context';

class AddressListReg extends Component {

  render(){
    return (
      <carWahshesContext.Consumer>
        {(carwashes) => {
          return (
            <div className="address-list">
              {carwashes.length > 0 ? carwashes.map((item) => (
                <registrationContext.Consumer>
                  {(value) => {
                    return (
                      <Link className="address-list__item" to="/registration/step-2" onClick={value.checkWasher.bind(this, item.PROPERTY_VALUES.EPR_CARWASH_ID)}>
                        <span className="address-list__body">
                          <span className="address-list__title">{item.PROPERTY_VALUES.ADRESS}</span>
                          <span className="address-list__subtitle">{item.PROPERTY_VALUES.DISTRICT}</span>
                        </span>
                      </Link>
                    )
                  }}
                </registrationContext.Consumer>
              )):''}
            </div>
          )
        }}
      </carWahshesContext.Consumer>
    );
  }
}

export default AddressListReg;
