import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";

import {registrationContext, carWahshesContext} from '../../context';
import Button from '../Button'
import icon1 from '../../media/img/icon/data-pin.svg'
import icon2 from '../../media/img/icon/data-cart.svg'
import icon3 from '../../media/img/icon/data-table.svg'

class Step5Failed extends Component {

  static contextType = registrationContext;

  dateInfo() {
    let mounthList = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    let timeItem = '';
    
    if(this.context.time) {
      timeItem = this.context.time.getDate() + ' ' + mounthList[this.context.time.getMonth()] + ' ' + this.context.time.getFullYear() + ', ' + (this.context.time.getHours()<10 ? '0'+this.context.time.getHours():this.context.time.getHours()) + ':' + (this.context.time.getMinutes()<10 ? '0'+this.context.time.getMinutes() : this.context.time.getMinutes())
    }
    return timeItem;
  }

  render(){
    
    if (this.context.servicesId.length < 1) {
      return <Redirect to='step-3'/>
    }

    return (      
      <div className="sing-up__step sing-up__step_6 is-active">
        <div className="sing-up__content">
          <div className="sing-up__head">
            <div className="sing-up__subtitle">Онлайн запись</div>
            <div className="sing-up__title">Проверьте данные</div>
          </div>
          <div className="sing-up__body">
            <div className="data-list">
              <Button className="data-list__item" to="step-1">
                <img className="data-list__img" src={icon1} alt="" role="presentation"/>
                <span className="data-list__text">
                <carWahshesContext.Consumer>
                  {(carwashes) => {
                    return (
                      carwashes.length > 0 ? carwashes.map((item) => (
                        console.log(item.ID  + '===' + this.context.carWasherId),
                        item.PROPERTY_VALUES.EPR_CARWASH_ID === this.context.carWasherId ? item.PROPERTY_VALUES.ADRESS : ''
                      )):''
                    )
                  }}
                </carWahshesContext.Consumer>
                </span>
              </Button>
              <Button className="data-list__item" to="step-2">
                <img className="data-list__img" src={icon3} alt="" role="presentation"/>
                <span className="data-list__text">
                  {this.dateInfo()}
                </span>
              </Button>
              <Button className="data-list__item" to="step-3">
                <img className="data-list__img" src={icon2} alt="" role="presentation"/>
                <span className="data-list__text">{this.context.serviceName}</span>
              </Button>
              {/* <a className="data-list__item" href="#">
                <img className="data-list__img" src="img/icon/data-profile.svg" alt="" role="presentation"/>
                <span className="data-list__text">Шалагина К., 1уке24</span>
              </a> */}
            </div>
          </div>
          <div className="sing-up__footer">
            <div className="sing-up__footer-wrap">
              <Button className='button' to= 'step-4' >Дальше</Button>
              <Button className='button button_text' to='step-3'>Назад</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step5Failed;
