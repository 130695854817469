import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import WantKnow from '../components/WantKnow';
import Button from '../components/Button'
import imageSection from '../media/img/components/image-section/selection.jpg'


class JantarSelection extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      showContent: false,
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('jantar.selection').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 
  }

  showContent = () => {
    this.setState({
      showContent:!this.state.showContent,
    });
  }

  render(){
    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <div className="selection-page">
            <section className="main-banner main-banner_sz_lg main-banner_bgi_selection selection-page__main-banner">
              <div className="container main-banner__container">
                <div className="main-banner__content">
                  <div className="main-banner__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK1_TITLE ? this.state.items.BLOCK1_TITLE.VALUE : '' }}></div>
                  <div className="main-banner__text"><p>{this.state.items.BLOCK1_DESCRIPTION ? this.state.items.BLOCK1_DESCRIPTION.VALUE : ''}</p></div>
                  <Button className='button main-banner__button' onClick={() => this.props.history.goBack()}>Назад</Button>
                  <div className="main-banner__text-2"><p>{this.state.items.BLOCK1_CONTENT ? this.state.items.BLOCK1_CONTENT.VALUE : ''}</p></div>
                </div>
              </div>
            </section>
            <section className="complete-cleaning selection-page__complete-cleaning">
              <div className="container complete-cleaning__container">
                <div className="complete-cleaning__grid">
                  <div className="complete-cleaning__grid-row">
                    <div className="complete-cleaning__grid-item">
                      <h2 className="complete-cleaning__title">{this.state.items.BLOCK2_TITLE ? this.state.items.BLOCK2_TITLE.VALUE : ''}</h2>
                      <p className="complete-cleaning__subtitle">{this.state.items.BLOCK2_SUBTITLE ? this.state.items.BLOCK2_SUBTITLE.VALUE : ''}</p>
                    </div>
                    <div className="complete-cleaning__grid-item">
                      <div className="complete-cleaning__text"><p>{this.state.items.BLOCK2_DESCRIPTION ? this.state.items.BLOCK2_DESCRIPTION.VALUE : ''}</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={"list-section list-section_theme_dark list-section-brands selection-page__list-section-brands " + (this.state.showContent ? 'active' : '')} show-block="">
              <div className="container list-section__container list-section-brands__container">
                <h2 className="list-section__title">{this.state.items.BLOCK3_TITLE ? this.state.items.BLOCK3_TITLE.VALUE : ''}</h2>
                <div className="list-section__grid">
                  <dl className="list-section__grid-row">
                    {this.state.items.BLOCK3_BRAND ? this.state.items.BLOCK3_BRAND.VALUE.map((item, index) => (
                      <div className="list-section__grid-item">
                        <dt className="list-section-brands__item-title">{item}</dt>
                        <dd className="list-section-brands__item-text">{this.state.items.BLOCK3_BRANDDESCRIPTION.VALUE[index]}</dd>
                      </div>
                    )):''}
                  </dl>
                </div>
                <button className="button-2 button-2_theme_gray list-section__show-btn" onClick={this.showContent}>Показать еще</button>
                <div className="list-section-brands__footer">
                  <h3 className="list-section-brands__footer-title">А также:</h3>
                  <ul className="list-section-brands__footer-list">
                    {this.state.items.BLOCK3_BRANDLIST ? this.state.items.BLOCK3_BRANDLIST.VALUE.map((item) => (
                      <li className="list-section-brands__footer-list-item">{item + ', '}</li>
                    )):''}
                  </ul>
                </div>
              </div>
            </section>
            <div className="image-section selection-page__image-section">
              <div className="image-section__image-wrap"><img className="image-section__image" src={imageSection} alt=""/></div>
            </div>
            <WantKnow />
          </div>
        </div>
      </main>
    );
  }
}

export default JantarSelection;
