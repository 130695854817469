import React, { Component } from 'react';
import moment from 'moment'

class Article extends Component {
  
  render(){
    function getDate(propsDate){
      let date = moment(propsDate, "DD-MM-YYYY");
      return  date.format('DD.MM.YYYY')
    }

    function cardType(type) {
      return type === ('Акция') ? 'article_type_sale' : ''
    }

    return (                 
      <span className={"article articles-grid__grid-item " + cardType(this.props.type)}>
        <span className="article__header">
          <span className="article__type">{this.props.type}</span>
          {this.props.date ? <time className="article__date">{getDate(this.props.date)}</time> : ''}
        </span>
        <span className="article__body">
          <h3 className="article__title">{this.props.title}</h3>
          <span className="article__text">
            <p>{this.props.description}</p>
          </span>
        </span>
      </span>
    );
  }
}

export default Article;
