import React, { Component } from 'react';
import {registrationContext} from '../context';

import BackendApi from '../BackendApi';
import moment from 'moment'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../media/datepicker-style.css";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

class Datepicker extends Component {


  state = {
    startDate: new Date()
  };

  static contextType = registrationContext;

  componentDidMount(){
    if(!this.context.date) {
      this.context.checkDate(this.state.startDate);
    } else {
      this.setState({
        startDate: new Date(this.context.date)
      });
    }

    setTimeout(() => {
      let checkTime = moment(this.state.startDate)
      BackendApi.emptyTime(checkTime.format('DD.MM.YYYY'), this.context.carWasherId).then((result) => {
        Array.isArray(result.data.result) ? this.context.emptyTimeList(result.data.result) : this.context.emptyTimeList([])
      })
    }, 500);
  }
 
  handleChange = date => {
    this.setState({
      startDate: date
    });
    this.context.checkDate(date);

    let checkTime = moment(date)
    BackendApi.emptyTime(checkTime.format('DD.MM.YYYY'), this.context.carWasherId).then((result) => {
      Array.isArray(result.data.result) ? this.context.emptyTimeList(result.data.result) : this.context.emptyTimeList([])
    }) 
  };

  render() {
    return (
      <div className="date" id="date">
        <DatePicker
          locale='ru'
          selected={this.state.startDate}
          minDate={new Date()}
          onChange={this.handleChange}
          inline
        />
      </div>
    )
  }
}

export default Datepicker;
