import React, { Component } from 'react';

import NewsGrid from '../components/NewsGrid'

class Loyalty extends Component {

  render(){
    return (      
      <main className="content">
        <div className="content__main">
          <div className="main-banner main-banner_sz_lg main-banner_bgi_loyalty">
            <div className="container main-banner__container">
              <div className="main-banner__content">
                <div className="main-banner__title">Лояльность</div>
                <div className="main-banner__text"><p>Сезонные скидки и предложения</p></div>
                {/* <AnchorLink className="button main-banner__button" href='#news-grid'>Смотреть</AnchorLink> */}
              </div>
            </div>
          </div>
          <div className="content-inner">
            <div className="content-inner__placeholder">
              <div className="container content-inner__container">
                <div className="news-section content-inner__section" id='news-grid'>
                  <NewsGrid />
                  {/* <a className="button-2 news-section__button" href="#!">Показать еще</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Loyalty;
