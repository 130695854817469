import React from 'react';
import {Link} from "react-router-dom";

function Button(props) {  
  if (props.to) {
    return (<Link {...props}></Link>);
  } else {
    return (<button {...props}></button>);
  }
  
}

export default Button;
