import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import WantKnow from '../components/WantKnow';
import CompanyCard from '../media/img/components/company-card/image-1.jpg'
import ImageSection from '../media/img/components/image-section/about.jpg'


class AboutCompany extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      showContent: false,
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('page.about').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 
  }

  showContent = () => {
    this.setState({
      showContent:!this.state.showContent,
    });
  }

  render(){
    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <div className="about-page">
            <section className="main-banner-about main-banner main-banner_sz_lg main-banner_bgi_about about-page__main-banner">
              <div className="container main-banner__container">
                <div className="main-banner__content main-banner-about__content"> 
                  <p className="main-banner__subtitle">{this.state.items.BLOCK1_TAG ? this.state.items.BLOCK1_TAG.VALUE : ''}</p>
                  <h1 className="main-banner__title main-banner-about__title" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK1_TITLE ? this.state.items.BLOCK1_TITLE.VALUE : '' }}></h1>
                  <div className="main-banner-about__principles-grid">
                    <ul className="main-banner-about__principles-grid-row">
                      {this.state.items.BLOCK1_ABILITIES ? this.state.items.BLOCK1_ABILITIES.VALUE.map((item) => (
                        <li className="main-banner-about__principles-grid-item">{item}</li>
                      )):''}
                    </ul>
                  </div>
                  <div className="main-banner-about__components-grid">
                    <div className="main-banner-about__components-grid-row">
                      <div className="main-banner-about__components-text main-banner-about__components-grid-item">{this.state.items.BLOCK1_DESCRIPTION ? this.state.items.BLOCK1_DESCRIPTION.VALUE : ''}</div>
                      <ul className="main-banner-about__components-list main-banner-about__components-grid-item">
                        {this.state.items.BLOCK1_SYSTEMS ? this.state.items.BLOCK1_SYSTEMS.VALUE.map((item) => (
                          <li className="main-banner-about__components-item" dangerouslySetInnerHTML={{ __html: item}}></li>
                        )):''}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="about-page__history-wrapper">
              <section className="tape-section about-page__tape-section">
                <div className="container tape-section__container">
                  <div className="tape-section__content">
                    <h2 className="tape-section__title">{this.state.items.BLOCK2_TITLE ? this.state.items.BLOCK2_TITLE.VALUE : ''}</h2>
                    <div className="tape-section__text"><p>{this.state.items.BLOCK2_DESCRIPTION ? this.state.items.BLOCK2_DESCRIPTION.VALUE : ''}</p></div>
                  </div>
                </div>
              </section>
              <section className="history-past SECTION about-page__history-past">
                <div className="container history-past__container">
                  <div className="history-past__grid">
                    <div className="history-past__grid-row">
                      {this.state.items.BLOCK3_TITLE ? this.state.items.BLOCK3_TITLE.VALUE.map((item, index) => (
                        <div className="history-item history-past__grid-item">
                          <h3 className="history-item__title">{item}</h3>
                          <div className="history-item__text"><p>{this.state.items.BLOCK3_DESCRIPTION.VALUE[index]}</p></div>
                        </div>
                      )):''}
                    </div>
                  </div>
                </div>
              </section>
              <div className="container">
                <div className="company-card about-page__company-card">
                  <div className="company-card__content">
                    <h2 className="company-card__title company-card__title_line">{this.state.items.BLOCK4_TITLE ? this.state.items.BLOCK4_TITLE.VALUE : ''}</h2>
                    <div className="company-card__text"><p>{this.state.items.BLOCK4_DESCRIPTION ? this.state.items.BLOCK4_DESCRIPTION.VALUE : ''}</p></div>
                  </div>
                  <div className="company-card__image-col">
                    <div className="company-card__image-wrap"><img className="company-card__image" src={CompanyCard} alt=""/></div>
                  </div>
                </div>
              </div>
            </div>
            <section className={"list-section about-page__list-section " + (this.state.showContent ? 'active' : '')} show-block=''>
              <div className="container list-section__container">
                <h2 className="list-section__title">{this.state.items.BLOCK5_TITLE ? this.state.items.BLOCK5_TITLE.VALUE : ''}</h2>
                <div className="list-section__grid">
                  <ul className="list-section__grid-row">
                    {this.state.items.BLOCK5_ADVANTAGES ? this.state.items.BLOCK5_ADVANTAGES.VALUE.map((item) => (
                      <li className="list-section__grid-item">{item}</li>
                    )):''}
                  </ul>
                </div>
                <button className="button-2 button-2_theme_gray list-section__show-btn" onClick={this.showContent}>Показать еще</button>
              </div>
            </section>
            <section className="lider-position SECTION about-page__lider-position">
              <div className="container lider-position__container">
                <div className="lider-position__row">
                  <h2 className="lider-position__title">{this.state.items.BLOCK6_TITLE ? this.state.items.BLOCK6_TITLE.VALUE : ''}</h2>
                  <div className="lider-position__content">
                    <blockquote className="lider-position__text" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK6_DESCRIPTION ? this.state.items.BLOCK6_DESCRIPTION.VALUE : '' }}></blockquote>
                    <cite className="lider-position__author" dangerouslySetInnerHTML={{ __html: this.state.items.BLOCK6_AUTHOR ? this.state.items.BLOCK6_AUTHOR.VALUE : '' }}></cite>
                  </div>
                </div>
              </div>
            </section>
            <div className="image-section about-page__image-section">
              <div className="image-section__image-wrap about-page__image-section-image-wrap"><img className="image-section__image" src={ImageSection} alt=""/></div>
            </div>
            <section className="company-values SECTION about-page__company-values">
              <div className="container company-values__container">
                <h2 className="company-values__title">{this.state.items.BLOCK7_TITLE ? this.state.items.BLOCK7_TITLE.VALUE : ''}</h2>
                <div className="company-values__grid">
                  <dl className="company-values__grid-row">
                    {this.state.items.BLOCK7_TAG ? this.state.items.BLOCK7_TAG.VALUE.map((item, index) => (
                      <div className="company-values__grid-item">
                        <dt className="company-values__item-title">{item}</dt>
                        <dd className="company-values__item-text">{this.state.items.BLOCK7_TAGDESCRIPTION.VALUE[index]}</dd>
                      </div>
                    )):''}
                  </dl>
                </div>
              </div>
            </section>

            <WantKnow />
          </div>
        </div>
      </main>
    );
  }
}

export default AboutCompany;
