import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import InputMask from 'react-input-mask';

import BackendApi from '../BackendApi';
import WantKnow from '../components/WantKnow';
import formBanner from '../media/img/components/company-card/image-2.jpg'
import preloader from '../media/preloader.svg'


class Corporate extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      submited: false,
      loading: false,
      COMPANY_NAME: '',
      CAR_COUNT: '',
      EMAIL: '',
      FIO: '',
      PHONE: '',
      fieldErrors: {},
      showContent: false,
    };
	}

	componentDidMount(){
		BackendApi.includedAreas('page.corparate').then((result) => {
			this.setState({
				items: result.data.result ? result.data.result:[],
      });
    }) 
  }

  showContent = () => {
    this.setState({
      showContent:!this.state.showContent,
    });
  }

  handleInputChange = field => e => {
    let {value} = e.target;
		let fieldErrors = {...this.state.fieldErrors};
    if(fieldErrors[field]) delete fieldErrors[field];
    
		this.setState({
			[field]: value,
			fieldErrors
		})
	};

  handleFormSubmit = e => {
    e.preventDefault();
    const {COMPANY_NAME, CAR_COUNT, EMAIL, FIO, PHONE} = this.state;
    let emptyField = false;
    let fieldList = {...this.state.fieldErrors};

    for(var key in this.state){
      if(this.state[key].length <= 0) {
        emptyField = true;        
        Object.assign(fieldList, {[key]: 'Заполните поле'});
      }
    }
    
    this.setState({
      fieldErrors: fieldList,
    })

    if(!emptyField) {
      this.setState({loading: true});
      BackendApi.corporateForm(COMPANY_NAME, CAR_COUNT, EMAIL, FIO, PHONE).then((result) => {
        switch(result.status)
        {
          case 200:
            this.setState({submited: 'Ваша заявка отправлена'});
            break;

          case 500:
            this.setState({submited: 'Произошла непредвиденная ошибка, попробуйте снова'});
            break;
          
            default:
              let fieldErrors = {};
              for(var key in result.data.errors){
                Object.assign(fieldErrors, {[result.data.errors[key].code]: result.data.errors[key].msg});
              }
              this.setState({
                fieldErrors
              })
          };

        this.setState({loading: false});
      })
    }
  }

  getInputClassError = field => {
		return !this.isValidField(field) ? 'error':'';
  };
  
  isValidField(field) {
    const {fieldErrors} = this.state;
		return !fieldErrors[field];
  }
  
  returnRequest = e => {
    e.preventDefault();
    this.setState({
      submited: false
    })
  }
  
  
  // beforeMaskedValueChange = (newState, oldState, userInput) => {
  //   var { value } = newState;
  //   var selection = newState.selection;
  //   var cursorPosition = selection ? selection.start : null;
 
  //   console.log(selection)
  //   if (value[3] !== '9') {
  //       value = '+7(___)___-__-__';
  //       selection = { start: cursorPosition--, end: cursorPosition-- };
  //   }
 
  //   return {
  //     value,
  //     selection
  //   };
  // }

  render(){
    const {COMPANY_NAME, CAR_COUNT, EMAIL, FIO, PHONE} = this.state;


    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <div className="corporate-page">
            <section className="main-banner main-banner_sz_lg main-banner_bgi_corporate corporate-page__main-banner">
              <div className="container main-banner__container">
                <div className="main-banner__content">
                  <div className="main-banner__title">{this.state.items.BLOCK2_TITLE ? this.state.items.BLOCK2_TITLE.VALUE : ''}</div>
                  <div className="main-banner__text"><p>{this.state.items.BLOCK1_DESCRIPTION ? this.state.items.BLOCK1_DESCRIPTION.VALUE : ''}</p></div>
                  <AnchorLink className="button main-banner__button" href="#order-form">Получить коммерческое предложение</AnchorLink>
                  <div className="main-banner__text-2"> 
                    <ul className="list-section__grid_wide">
                      {this.state.items.BLOCK1_ADVANTAGES ? this.state.items.BLOCK1_ADVANTAGES.VALUE.map((item) => (
                        <li className="list-section__grid-item">{item}</li>
                      )):''}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className={"list-section list-section_bgp_reverse corporate-page__list-section " + (this.state.showContent ? 'active' : '')} show-block=''>
              <div className="container list-section__container">
                <h2 className="list-section__title">{this.state.items.BLOCK2_TITLE ? this.state.items.BLOCK2_TITLE.VALUE : ''}</h2>
                <div className="list-section__grid">
                  <ul className="list-section__grid-row">
                    {this.state.items.BLOCK2_LIST ? this.state.items.BLOCK2_LIST.VALUE.map((item) => (
                      <li className="list-section__grid-item">{item}</li>
                    )):''}
                  </ul>
                </div>
                <button className="button-2 button-2_theme_gray list-section__show-btn" onClick={this.showContent}>Показать еще</button>
              </div>
            </section>
            <section className="corporate-advantages corporate-page__corporate-advantages">
              <div className="container corporate-advantages__container">
                <div className="corporate-advantages__grid">
                  <ul className="corporate-advantages__grid-row">
                    {this.state.items.BLOCK3_ADVANTAGETITLE ? this.state.items.BLOCK3_ADVANTAGETITLE.VALUE.map((item, index) => (
                      <li className="corporate-advantages__grid-item">
                        <div className="corporate-advantages__item">
                          <div className="corporate-advantages__item-icon" style={{backgroundImage: 'url(' + this.state.items.BLOCK3_ICON.VALUE[index] + ')'}}></div>
                          <div className="corporate-advantages__item-title">{item}</div>
                          <div className="corporate-advantages__item-text">{this.state.items.BLOCK3_ADVANTAGEDESCRIPTION.VALUE[index]}</div>
                        </div>
                      </li>
                    )):''}
                  </ul>
                </div>
              </div>
            </section>
            <section className="our-clients corporate-page__our-clients">
              <div className="container our-clients__container">
                <h2 className="our-clients__title">{this.state.items.BLOCK4_TITLE ? this.state.items.BLOCK4_TITLE.VALUE : ''}</h2>
                <div className="our-clients__grid">
                  <ul className="our-clients__grid-row">
                    {this.state.items.BLOCK4_CLIENT ? this.state.items.BLOCK4_CLIENT.VALUE.map((item, index) => (
                      <li className="our-clients__grid-item">
                        <a className="our-clients__item-image-wrap" href={this.state.items.BLOCK4_CLIENT.DESCRIPTION[index]} target="_blank" rel="noopener noreferrer"><img className="our-clients__item-image" src={item} alt=""/></a>
                      </li>
                    )):''}
                  </ul>
                </div>
              </div>
            </section>
            <section className="tape-section corporate-page__tape-section">
              <div className="container tape-section__container">
                <div className="tape-section__content">
                  <h2 className="tape-section__title">{this.state.items.BLOCK5_TITLE ? this.state.items.BLOCK5_TITLE.VALUE : ''}</h2>
                  <div className="tape-section__text"><p>{this.state.items.BLOCK5_DESCRIPTION ? this.state.items.BLOCK5_DESCRIPTION.VALUE : ''}</p></div>
                  <a className="button button_theme_white tape-section__button" href={this.state.items.BLOCK5_FILE ? this.state.items.BLOCK5_FILE.VALUE : ''} target="_blank" rel="noopener noreferrer">Скачать файл</a>
                </div>
              </div>
            </section>
            <section className="contacts-section corporate-page__contacts-section">
              <div className="container contacts-section__container">
                <h2 className="contacts-section__title">{this.state.items.BLOCK6_TITLE ? this.state.items.BLOCK6_TITLE.VALUE : ''}</h2>
                <div className="contacts-section__grid">
                  <div className="contacts-section__grid-row">
                    <div className="contact-info-2 contact-info-2 contact-info-2_icon_phone contacts-section__grid-item">
                      <div className="contact-info-2__content">
                        <p className="contact-info-2__title">Позвонить</p>
                        <ul className="contact-info-2__list">
                          {this.state.items.BLOCK6_PHONE ? this.state.items.BLOCK6_PHONE.VALUE.map((item) => (
                            <li className="contact-info-2__list-item">
                              <a className="contact-info-2__list-link" href={'tel:' + item}>{item}</a>
                            </li>
                          )):''}
                        </ul>
                      </div>
                    </div>
                    <div className="contact-info-2 contact-info-2 contact-info-2_icon_mail contacts-section__grid-item">
                      <div className="contact-info-2__content">
                        <p className="contact-info-2__title">Написать</p>
                        <ul className="contact-info-2__list">
                          {this.state.items.BLOCK6_EMAIL ? this.state.items.BLOCK6_EMAIL.VALUE.map((item) => (
                            <li className="contact-info-2__list-item">
                              <a className="contact-info-2__list-link" href={'mailto:' + item}>{item}</a>
                            </li>
                          )):''}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <section className="company-card contacts-section__company-card" id='order-form'>
                  <div className="order-form company-card__content">
                    <div className="order-form__inner">
                      {!this.state.submited ?
                        <h3 className="order-form__title">Оставьте заявку на корпоративное обслуживание</h3>
                      : <h3 className="order-form__title">{this.state.submited}</h3>}
                      <form className="order-form__form" onSubmit={this.handleFormSubmit}>
                        <div className="order-form__fields" style={this.state.submited ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                          <div className="order-form__fields-row">
                            <input className={"input input_sz_lg order-form__fields-item " + this.getInputClassError('COMPANY_NAME')} placeholder="Название компании" onChange={this.handleInputChange('COMPANY_NAME')} value={COMPANY_NAME} />
                            <input className={"input input_sz_lg order-form__fields-item " + this.getInputClassError('CAR_COUNT')} placeholder="Количество авто" onChange={this.handleInputChange('CAR_COUNT')} value={CAR_COUNT}/>
                            <input className={"input input_sz_lg order-form__fields-item " + this.getInputClassError('EMAIL')} placeholder="Почта" onChange={this.handleInputChange('EMAIL')} value={EMAIL}/>
                            <input className={"input input_sz_lg order-form__fields-item order-form__fields-item_wd_half " + this.getInputClassError('FIO')} placeholder="Контактное лицо" onChange={this.handleInputChange('FIO')} value={FIO}/>
                            <InputMask className={"input input_sz_lg order-form__fields-item order-form__fields-item_wd_half " + this.getInputClassError('PHONE')} placeholder="Номер телефона" onChange={this.handleInputChange('PHONE')} value={PHONE} mask="+7(999)999-99-99" maskChar="_" />
                          </div>
                        </div>
                        {!this.state.submited ?
                          <button className="button button_sz_md order-form__submit-btn" type="submit" style={{position:'relative'}}>{!this.state.loading ? 'Отправить' : <img src={preloader} style={{height:'40px', margin:'-13px 0'}} alt=''/>}</button>
                          : <button className="button button_sz_md order-form__submit-btn" onClick={this.returnRequest} type="submit">Хорошо</button>}
                      </form>
                    </div>
                  </div>
                  <div className="company-card__image-col">
                    <div className="company-card__image-wrap"><img className="company-card__image" src={formBanner} alt=""/></div>
                  </div>
                </section>
              </div>
            </section>
            <WantKnow />
          </div>
        </div>
      </main>
    );
  }
}

export default Corporate;
