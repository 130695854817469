import React from 'react';

function Hashtags(props) {
  if(props.list.length > 0) {
    var item = props.list.map((item, i) => {
      return <div className="hashtags__item" dangerouslySetInnerHTML={{ __html: props.list[i] }}></div>
    });
  }

  return (
    <div className="hashtags">
      {item}
    </div>
  );
}

export default Hashtags;
