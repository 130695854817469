import React, { Component } from 'react';
import {carWahshesContext} from '../context';

import ContactInfoSlider from '../components/ContactInfoSlider'
import ServiceBanner from '../components/ServiceBanner'
import Button from '../components/Button'



class Addresses extends Component {

  render(){

    var hashLink = window.location.hash;
    var anchorElem = document.getElementById(hashLink);
    if (anchorElem) {
      setTimeout(() => {
        var elemPos = anchorElem.offsetTop
        var elemHeight = anchorElem.offsetHeight 
        console.log(elemHeight)
        window.scrollTo({
          top: elemPos + elemHeight,
          // behavior: "smooth"
        })
      }, 1000)
    }

    return (      
      <carWahshesContext.Consumer>
        {(carwashes) => {
          return (
            <main className="content">
              <div className="content__main">
              <div className="scroll-button"></div>
                <div className="main-banner main-banner_sz_lg main-banner_bgi_addresses">
                  <div className="container main-banner__container">
                    <div className="main-banner__content">
                      <p className="main-banner__subtitle">Адреса автомоек Янтарь</p>
                      <div className="main-banner__title">Адреса и обзор<br /> наших точек</div>
                      <div className="main-banner__text"><p>Янтарь - это создание реального имиджа не словами, а результатом!</p></div>
                      <Button className='button main-banner__button' to= '/registration/'>Записаться</Button>
                    </div>
                  </div>
                </div>

                <div className="content-inner">
                  <div className="content-inner__placeholder">
                    <div className="container content-inner__container">
                      <div className="contacts-grid">
                        <div className="contacts-grid__grid">
                          <div className="contacts-grid__grid-row">
                            {carwashes.length > 0 ? carwashes.map((item) => (
                              <ContactInfoSlider key = {item.ID} idContact = {item.ID} address = {item.PROPERTY_VALUES.ADRESS} photo = {item.PREVIEW_PICTURE} boxes = {item.PROPERTY_VALUES.COUNT_BOX} description = {item.NAME}/>
                            )):''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ServiceBanner />
              </div>
            </main>
          )
        }}
      </carWahshesContext.Consumer>
    )
  }
}

export default Addresses;
