import React from 'react';

import Logo from './Logo';
import DesktopMenu from './DesktopMenu';
import PhoneButton from './PhoneButton';
import Hamburger from './Hamburger';

function HeadDashboard(props) {
  return (
    <div className="head-dashboard head-dashboard_inner" id="head-dashboard">
      <div className="head-dashboard__wrapper">
        <div className="head-dashboard__group">
          <Logo closeMenu={props.closeMenu} />
          <DesktopMenu closeMenu={props.closeMenu} />
        </div>
        <div className="head-dashboard__group">
          <PhoneButton />
          <Hamburger toggleMenu={props.toggleMenu}/>
        </div>
      </div>
    </div>
  );
}

export default HeadDashboard;
