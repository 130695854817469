import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {registrationContext} from '../../context';

import BackendApi from '../../BackendApi';
import Button from '../Button'
import RadioButton from '../RadioButton'


class Step3 extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      serviceList: [],
      descriptionList: [],
    };
  }
  
  static contextType = registrationContext;

	componentDidMount(){
    this.context.stepNumber(this.props.location.pathname);
    setTimeout( () => {
      this.context.checkServices('', '')
    }, 900)

		BackendApi.serviceListSite().then((result) => {
			this.setState({
				items: Array.isArray(result.data.result) ? result.data.result:[],
      });
    })    
    
    BackendApi.iblockInfo(2).then((result) => {
      let descriptionList = {}
      if (Array.isArray(result.data.result)) {
        result.data.result.forEach(element => {
          descriptionList[element.PROPERTY_VALUES.EPR_SERVICE_ID] = element.PROPERTY_VALUES.CAPTION
        });
      }
			this.setState({
				descriptionList: descriptionList,
      });
    })  
  }

  handleCheckboxChange = serviceId => e => {
    let serviceList = [...this.state.serviceList];
    let serviceName;  

    serviceList = [serviceId];
    
		this.setState({
      serviceList: serviceList
    })

    
    if (serviceList.length === 1) {
      this.state.items.map((item) => (
        item.ID === serviceList[0] ? serviceName = item.NAME : ''
      ))        
    } else if(serviceList.length > 1) {
      this.state.items.map((item) => (
        item.ID === serviceList[0] ? serviceName = item.NAME + ' <span> +' + (serviceList.length - 1) + '</span>' : ''
      ))  
    }
    this.context.checkServices(serviceList, serviceName)
  };
  
  buttonType() {
    if(this.context.servicesId.length > 0) {
      if(window.screen.width > 768) {
        return <Button className='button' to= 'step-4' >Дальше</Button>
      } else {
        return <Button className='button' to= 'step-4-info' >Дальше</Button>
      }
    } else {
      return <Button className='button is-disable' disabled title='Выберите услугу'>Дальше</Button>
    }
  }

  serviceList() {
    let serviceList = [];
    if(this.state.items.length > 0) {     
      serviceList = [];

      for(var key in this.state.items) {
        serviceList.push(
          <div className="service-list__item">
            <RadioButton key={this.state.items[key].ID} id={this.state.items[key].ID} title={this.state.items[key].NAME} subtitle={this.state.descriptionList[this.state.items[key].ID]}  handleFunction ={this.handleCheckboxChange}/>
          </div>
        )
      }
    } else {
      for (let i = 0; i < 8; i++) {
        serviceList.push(
          <div className="service-list__item">
            <RadioButton key={i} id={i} title='Услуга' subtitle='' handleFunction ={this.handleCheckboxChange} />
          </div>
        )
      }
    }

    return serviceList;
  }

  render(){

    if (!this.context.date) {
      return <Redirect to='step-2'/>
    }

    return (      
      <div>
        <div className="sing-up__body">
          <div className="service-list">
            {this.serviceList()}
          </div>
        </div>
        <div className="sing-up__footer">
          <div className="sing-up__footer-wrap">
            {this.buttonType()}
            <Button className='button button_text' to='step-2'>Назад</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step3;
