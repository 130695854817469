import React from 'react';

import ContactList from './ContactList';

function PhoneButton() {
  return (
    <button className="phone-button">
      <ContactList />
    </button>
  );
}

export default PhoneButton;
