import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from '../components/Button';
import catImg1 from '../media/img/components/price-category/image-1.svg'
import catImg2 from '../media/img/components/price-category/image-2.svg'
import catImg3 from '../media/img/components/price-category/image-3.svg'
import catImg4 from '../media/img/components/price-category/image-4.svg'

class SearchCategory extends Component {

  constructor(props){
		super(props);

		this.state = {
      valueBrand: '',
      brand: [],
      brandId: '', 
      valueModel: '',
      model: [],
      modelId: '',
      modelName: '',
      categoryId: '',
      category: [],
    };

    this.handleChangeBrand = this.handleChangeBrand.bind(this);
    this.clickTooltipBrand = this.clickTooltipBrand.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.clickTooltipModel = this.clickTooltipModel.bind(this);
  }

  handleChangeBrand(event) {
    this.setState({
      valueBrand: event.target.value,
      valueModel: '',
      model: '',
      modelId: '',
      categoryId: '',
    });

    this.getBrand(event.target.value);
  }

  handleChangeModel(event) {
    this.setState({
      valueModel: event.target.value
    });

    if(this.state.brandId.length > 0) {
      this.getModel(event.target.value, this.state.brandId);
    }
  }
  
  clickTooltipBrand(event) {
    this.setState({
      valueBrand: event.target.textContent,
      brandId: event.target.getAttribute('data-id')
    });

  }
  
  clickTooltipModel(event) {
    this.setState({
      valueModel: event.target.textContent,
      modelName: event.target.textContent,
      modelId: event.target.getAttribute('data-id'),
      categoryId: event.target.getAttribute('data-category'),
    });
  }
  
  getBrand(inputData) {
    if(inputData.length > 0) {
      BackendApi.getBrand(inputData).then((result) => {
        if(result.data.result.length === 1) {
          this.setState({
            brand: Array.isArray(result.data.result) ? result.data.result:[],
            brandId:result.data.result[0].ID,
          });
        } else {
          this.setState({
            brand: Array.isArray(result.data.result) ? result.data.result:[],
          });
        }
      }) 
    } else {
      this.setState({
        brand:'',
      });
    }
  };


  getModel(inputData, barndId) {
    if(inputData.length > 0) {
      BackendApi.getModel(inputData, barndId).then((result) => {
        if(result.data.result.length === 1) {
          this.setState({
            model: Array.isArray(result.data.result) ? result.data.result:[],
            modelId:Array.isArray(result.data.result) ? result.data.result[0].ID:[],
            categoryId:Array.isArray(result.data.result) ? result.data.result[0].CAR_CATEGORY_ID:[],
            modelName: Array.isArray(result.data.result) ? result.data.result[0].NAME:[],
          });
        } else if (result.data.result.length > 0) {
          for (var key in result.data.result) {
            if(result.data.result[key].NAME === inputData ){
              this.setState({
                model: Array.isArray(result.data.result) ? result.data.result:[],
                modelId:Array.isArray(result.data.result) ? result.data.result[key].ID:[],
                categoryId:Array.isArray(result.data.result) ? result.data.result[key].CAR_CATEGORY_ID:[],
                modelName: Array.isArray(result.data.result) ? result.data.result[key].NAME:[],
              });
              break;
            } else {
              this.setState({
                model: Array.isArray(result.data.result) ? result.data.result:[],
                modelId: '',
                categoryId: '',
                modelName: '',
              });
            }
          }

          this.setState({
            model: Array.isArray(result.data.result) ? result.data.result:[],
          });
        } else {
          this.setState({
            model:'',
            modelId: '',
            categoryId: '',
            modelName: '',
          });
        }
      }) 
    } else {
      this.setState({
        model:'',
        modelId: '',
        categoryId: '',
        modelName: '',
      });
    }
  };

  componentDidMount(){

    BackendApi.getCategoryList().then((result) => {
      this.setState({
        category: Array.isArray(result.data.result) ? result.data.result:[],
      });
    }) 
  }



  
  render(){
    
    function activeTooltip(tooltipData, valueBrand) {
      let activeClass = '';
      if (tooltipData.length > 0) {
        activeClass = 'show';
        for (var key in tooltipData) {
          if(tooltipData[key].NAME === valueBrand) {
            activeClass = '';
          }
        }

      } else if (valueBrand.length > 0) {
        activeClass = 'show';
      }

      return activeClass;
    };

    function showInputBlock(stateData) {
      let display = 'none';
      if (stateData.brand.length > 0) {
        display = 'none';
        for (var key in stateData.brand) {
          if(stateData.brand[key].NAME === stateData.valueBrand) {
            display = 'block';
          }
        }
      } else {
        display = 'none'
      }

      return display;
    };

    function categoryShow(stateData) {
      let categoryImg = {
        '1': catImg1,
        '2': catImg2,
        '3': catImg3,
        '4': catImg4,
      }
      if((stateData.category.length > 0) && (stateData.categoryId.length > 0)) {
        for (var key in stateData.category) {
          if (stateData.category[key].ID === stateData.categoryId) {
            return <li className="search-banner__results-item">
              <span className="search-result search-banner__results-link">
                <div className="search-result__image-wrap">
                  <img className="search-result__image" src={categoryImg[stateData.category[key].ID]} alt=""/>
                  </div>
                <div className="search-result__content">
                  <div className="search-result__model">{stateData.valueBrand + ' ' + stateData.modelName}</div>
                  <div className="search-result__category">{stateData.category[key].ID} категория</div>
                </div>
              </span>
            </li>
          }
        }
      } else {
        return ''
      }   
    }

    return (      
      <main className="content">
        <div className="content__main">
          <div className="search-page">
            <section className="main-banner main-banner_sz_lg search-banner search-page__search-banner main-banner_bgi_search">
              <div className="container main-banner__container search-banner__container">
                <h1 className="main-banner__title search-banner__title">Список машин <b className="main-banner__title-mark">по категориям</b></h1>
                <div className="search-banner__content">
                  <form className="search-banner__form">
                    <div className="search-field search-banner__search-field">
                      <div className="search-field__field">
                        <input className="input search-field__input" placeholder="Какая у вас марка машины?" value={this.state.valueBrand} onChange={this.handleChangeBrand}/>
                        <span className="search-field__submit" aria-label="Найти"></span>
                      </div>
                      <ul className={'search-field__list ' + activeTooltip(this.state.brand, this.state.valueBrand)}>
                        {this.state.brand.length > 0 ? this.state.brand.map((item) => (
                          <li className="search-field__list-item">
                            <span className="search-field__list-link" data-id={item.ID} onClick={this.clickTooltipBrand}>{item.NAME}</span>
                          </li>
                        )): <li className="search-field__list-item"> <span className="search-field__list-link">Данная марка авто не найдена</span></li>}
                      </ul>
                    </div>
                    
                    <div className="search-field search-banner__search-field" style={{display: showInputBlock(this.state)}}>
                      <div className="search-field__field">
                        <input className="input search-field__input" placeholder="Какая у вас модель машины?" value={this.state.valueModel} onChange={this.handleChangeModel}/>
                        <span className="search-field__submit" aria-label="Найти"></span>
                      </div>
                      <ul className={'search-field__list ' + activeTooltip(this.state.model, this.state.valueModel)}>
                        {this.state.model.length > 0 ? this.state.model.map((item) => (
                          <li className="search-field__list-item">
                            <span className="search-field__list-link" data-id={item.ID} data-category={item.CAR_CATEGORY_ID} onClick={this.clickTooltipModel}>{item.NAME}</span>
                          </li>
                        )): <li className="search-field__list-item"> <span className="search-field__list-link">Данная марка авто не найдена</span></li>}
                      </ul>
                    </div>
                  </form>

                  <div className="search-banner__text">Впишите в поисковую строку марку машины, чтобы узнать ее категорию</div>
                  <ul className="search-banner__results-list">
                    {categoryShow(this.state)}
                  </ul>
                  <Button className='button-2 button-2_sz_sm button-2_theme_white search-banner__back-btn' onClick={() => this.props.history.goBack()}>Назад</Button>
                  
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export default SearchCategory;
