import React, { Component } from 'react';

import BackendApi from '../BackendApi';
import Button from '../components/Button'
import ServiceCard from '../components/ServiceCard'
import ProgramRecomendation from '../components/ProgramRecomendation';
import WantKnow from '../components/WantKnow';
import SideService from '../components/SideService';


class Services extends Component {

  constructor(props){
		super(props);

		this.state = {
      items: [],
      content: [],
      showSideContent: false,
      serviceId: '',
    };
	}

	componentDidMount(){
    BackendApi.includedAreas('page.service').then((result) => {
      this.setState({
        content: result.data.result ? result.data.result:[],
      });
    }) 

		BackendApi.iblockInfoSort(9).then((result) => {
			this.setState({
				items: Array.isArray(result.data.result) ? result.data.result:[],
      });
      if (window.location.hash) {
        this.setState({
          serviceId: window.location.hash.split("#")[1],
        });
      }
    })    
  }

  showContent = (e) => {
    let serviceId = e.target.getAttribute('data-service');
    if(this.state.showSideContent){
      document.body.classList.remove('overlay');
      this.setState({
        showSideContent: false,
        serviceId: serviceId,
      })

      setTimeout(() => {
        document.body.classList.add('overlay');
        window.location.hash= serviceId;
        this.setState({
          showSideContent: true,
        });
      }, 400)

    } else {
      window.location.hash= serviceId;
      this.setState({
        // showSideContent: true,
        serviceId: serviceId,
      });
      setTimeout( () => {
        document.body.classList.add('overlay');
        this.setState({
          showSideContent: true,
          // serviceId: serviceId,
        });
      }, 200)

    }
  }
  
  closeSide = (e) => {
    document.body.classList.remove('overlay');
    this.setState({
      showSideContent: false,
    });
    var link = document.URL
    link = link.split('#')[0]
    window.history.pushState('', '', link);
  }
  
  openSide = (e) => {
    document.body.classList.add('overlay');
    this.setState({
      showSideContent: true,
    });
  }


  render(){

    function cardList(stateData, type) {
      if(stateData.state.items.length > 0) {        
        const cardList = stateData.state.items.map((item) => (
          item.PROPERTY_VALUES.CATEGORY === type ? <ServiceCard 
            showFunction={stateData.showContent}
            key={item.ID} 
            title={item.NAME} 
            caption={item.PROPERTY_VALUES.CAPTION} 
            price={item.PROPERTY_VALUES.PRICE} 
            img={item.PROPERTY_VALUES.IMAGE} 
            baseId={item.PROPERTY_VALUES.SERVICE_BASE}
            complexId={item.PROPERTY_VALUES.SERVICE_COMPLEX}
          />: ''
        ));
        return cardList;
      }
    }
    

    return (      
      <main className="content">
        <div className="content__main">
          <div className="scroll-button"></div>
          <section className="main-banner main-banner main-banner_sz_lg main-banner_bgi_services">
            <div className="container main-banner__container">
              <div className="main-banner__content">
                <p className="main-banner__subtitle">{this.state.content.BLOCK1_SUBTITLE ? this.state.content.BLOCK1_SUBTITLE.VALUE : ''}</p>
                <div className="main-banner__title" dangerouslySetInnerHTML={{ __html: this.state.content.BLOCK1_TITLE ? this.state.content.BLOCK1_TITLE.VALUE : '' }}></div>
                <div className="main-banner__text"><p>{this.state.content.BLOCK1_DESCRIPTION ? this.state.content.BLOCK1_DESCRIPTION.VALUE : ''}</p></div>
                <Button className='button main-banner__button' to= '/registration/'>Записаться</Button>
              </div>
            </div>
          </section>
          <div className="content-inner">
            <div className="content-inner__placeholder">
              <div className="container content-inner__container">
                <div className="services-grid">

                  <div className="services-section services-grid__section">
                    <h2 className="services-section__title">Базовые услуги мойки</h2>
                    <div className="articles-grid services-section__body"></div>
                    <div className="articles-grid__grid">
                      <div className="articles-grid__grid-row">
                        {cardList(this, 'base')}
                      </div>
                    </div>
                  </div>

                  <div className="services-section services-grid__section">
                    <h2 className="services-section__title">Детейлинг</h2>
                    <div className="articles-grid services-section__body"></div>
                    <div className="articles-grid__grid">
                      <div className="articles-grid__grid-row">
                        {cardList(this, 'detailing')}
                      </div>
                    </div>
                  </div>

                  <div className="services-section services-grid__section">
                    <h2 className="services-section__title">Дополнительные услуги</h2>
                    <div className="articles-grid services-section__body"></div>
                    <div className="articles-grid__grid">
                      <div className="articles-grid__grid-row">
                        {cardList(this, 'extra')}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <ProgramRecomendation />
        <WantKnow />
        <SideService closeSide={this.closeSide} openSide={this.openSide} showContent={this.state.showSideContent} serviceId={this.state.serviceId}/>
        <div className='page__overlay' style={{display: this.state.showSideContent ? 'block': 'none'}} onClick={this.closeSide}></div>
      </main>
    );
  }
}

export default Services;
