import React, { Component} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import {carWahshesContext, servicesContext} from './context';


import './media/css/normalize.css';
import 'slick-carousel/slick/slick.css';
import './media/css/libs.css';
import './media/css/template.css';
import './media/react-style.css'
import BackendApi from './BackendApi';
import AppWindow from './components/AppWindow';
import Header from './components/Header';
import RoutGroup from './components/RoutGroup';
import ScrollToTop from './components/ScrollToTop';
import CallForm from './components/CallForm';


class App extends Component {

  constructor(props){
		super(props);

		this.state = {
      carwashes: [],
      services: [],
    };
	}

	componentDidMount(){
		BackendApi.iblockInfo(1).then((result) => {
			this.setState({
				carwashes: Array.isArray(result.data.result) ? result.data.result:[],
      });
    })

		BackendApi.serviceListApp().then((result) => {
			this.setState({
				services: Array.isArray(result.data.result) ? result.data.result:[],
      });
    })    
  }
  
  
  
  render(){
    
    return (
      <carWahshesContext.Provider value={this.state.carwashes}>
        <servicesContext.Provider value={this.state.services}>
          <AppWindow />
          <div className='awPageWrapper'>    
            <Router>
              <ScrollToTop />
              <Header />
              <CallForm />
              <RoutGroup/>
            </Router>
          </div>
        </servicesContext.Provider>
      </carWahshesContext.Provider>
    );
  }
}

export default App;
