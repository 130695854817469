import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import {carWahshesContext} from '../context';

import Button from './Button'
import BackendApi from '../BackendApi';

import mhIcon from '../media/img/icon/machineheads.svg' 

class MobileMenu extends Component {

  constructor(props){
		super(props);

		this.state = {
      privacy: '',
    };
  }

  componentDidMount(){
    BackendApi.iblockInfo(11, 162).then((result) => {
			this.setState({
				privacy: Array.isArray(result.data.result) ? result.data.result[0]:'',
      });
    })
  }

  render(){
    return (
      <div className={"mobile-menu " + (this.props.openMenu ? 'is-open' : '')} id="mobile-menu">
        <div className="mobile-menu__wrapper">
          <div className="mobile-menu__head">
            <button className="mobile-menu__close" onClick={this.props.closeMenu}></button>
          </div>
          <div className="mobile-menu__body">
            <div className="mobile-list">
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" to='/' onClick={this.props.closeMenu}>Главная</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/services/' onClick={this.props.closeMenu}>Услуги</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/about-company/' onClick={this.props.closeMenu}>О нас</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/app/' onClick={this.props.closeMenu}>Приложение</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/recommendations/' onClick={this.props.closeMenu}>Программы рекомендаций</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/corporate/' onClick={this.props.closeMenu}>Корпоративным клиентам</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/registration/' onClick={this.props.closeMenu}>Запись на время</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/addresses/' onClick={this.props.closeMenu}>Адреса</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/loyalty/' onClick={this.props.closeMenu}>Акции и новости</NavLink>
              </div>
              <div className="mobile-list__item">
                <NavLink className="mobile-list__link" activeStyle={{pointerEvents: "none"}} to='/contacts/' onClick={this.props.closeMenu}>Контакты</NavLink>
              </div>
            </div>
            <div className="little-menu">
              <div className="little-menu__item">
                <NavLink className="little-menu__link" activeStyle={{pointerEvents: "none"}} to='/jantar-technology/' onClick={this.props.closeMenu}>Янтарь.<span>Technology</span></NavLink>
              </div>
              <div className="little-menu__item">
                <NavLink className="little-menu__link" activeStyle={{pointerEvents: "none"}} to='/jantar-team/' onClick={this.props.closeMenu}>Янтарь.<span>Team</span></NavLink>
              </div>
              <div className="little-menu__item">
                <NavLink className="little-menu__link" activeStyle={{pointerEvents: "none"}} to='/jantar-selection/' onClick={this.props.closeMenu}>Янтарь.<span>Selection</span></NavLink>
              </div>
              <div className="little-menu__item">
                <NavLink className="little-menu__link" activeStyle={{pointerEvents: "none"}} to='/app/' onClick={this.props.closeMenu}>Приложение «Янтарь»</NavLink>
              </div>
              <div className="little-menu__item">
                <NavLink className="little-menu__link" activeStyle={{pointerEvents: "none"}} to='/recommendations/' onClick={this.props.closeMenu}>Программы рекомендаций</NavLink>
              </div>
              <div className="little-menu__item">
                <a className="little-menu__link" href={this.state.privacy ? this.state.privacy.PROPERTY_VALUES.docs : ''} target="_blank" rel="noopener noreferrer">Политика конфинденциальности</a>
              </div>
            </div>
          </div>

          <carWahshesContext.Consumer>
            {(carwashes) => {
              return (
                <div className="mobile-menu__footer">
                  <div className="contact-list contact-list_black">
                    {carwashes.length > 0 ? carwashes.map((item) => (
                      <a className="contact-list__item" href={'tel:' + item.PROPERTY_VALUES.PHONE}>
                        <span className="contact-list__title">{item.PROPERTY_VALUES.ADRESS}</span>
                        <span className="contact-list__value">{item.PROPERTY_VALUES.PHONE}</span>
                      </a>
                    )):''}
                  </div>
                  <Button className='button' to='/registration/' onClick={this.props.closeMenu}>Запись на время</Button>
                  <a className="machineheads" href="https://machineheads.ru/" target="_blank" rel="noopener noreferrer">
                    <span>Разработано</span>
                    <img className="machineheads__img" src={mhIcon} alt="" role="presentation"/>
                    <span>MachineHeads</span>
                  </a>
                </div>
              )
            }}
          </carWahshesContext.Consumer>
        </div>
      </div>
    );
  }
}

export default MobileMenu;
