import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import moment from 'moment'


import {registrationContext} from '../../context';
import BackendApi from '../../BackendApi';
import Button from '../Button'
import Input from '../Input'

class Step4 extends Component {

  constructor(props){
		super(props);

		this.state = {
      submited: false,
      loading: false,
      name: '',
      phone: '',
      carNumber: '',
      fieldErrors: {},
      privacy: '',
    };
  }

  static contextType = registrationContext;

  componentDidMount(){
    this.context.stepNumber(this.props.location.pathname);

    BackendApi.iblockInfo(11, 162).then((result) => {
			this.setState({
				privacy: Array.isArray(result.data.result) ? result.data.result[0]:'',
      });
    })
  }

  handleInputChange = field => e => {
    let {value} = e.target;

		let fieldErrors = {...this.state.fieldErrors};
    if(fieldErrors[field]) delete fieldErrors[field];
    
		this.setState({
			[field]: value,
			fieldErrors
		})
	};
  
  handleFormSubmit = e => {
    e.preventDefault();
    const {name, phone, carNumber} = this.state;
    let emptyField = false;
    let fieldList = {...this.state.fieldErrors};

    for(var key in this.state){
      if(this.state[key].length <= 0) {
        emptyField = true;        
        Object.assign(fieldList, {[key]: 'Заполните поле'});
      }
    }
    
    this.setState({
      fieldErrors: fieldList,
    })

    if(!emptyField) {
      let time = moment(this.context.time);
      time = time.format('YYYY-MM-DDTHH:mm:ss');
      let carNumberFormat = carNumber.split('_')[0].toLowerCase()

      let formData = {
        carWashId: this.context.carWasherId,
        startDate: time,
        serviceList: this.context.servicesId,
        carNumber: carNumberFormat,
        phone: phone,
        name: name,
      }
      this.setState({loading: true});
      BackendApi.orderForm(formData).then((result) => {
        switch(result.status)
        {
          case 200:
            this.setState({submited: 'Ваша заявка отправлена'});
            window.location.assign('step-5');
            break;

          case 500:
            this.setState({submited: 'Произошла непредвиденная ошибка, попробуйте снова'});
            window.location.assign('step-5-failed');
            break;
          
            default:
              const mapApiFields = {
                'car_number': 'carNumber',
                'client_phone': 'phone',
              };

              let fieldErrors = {};

              for(let key in mapApiFields) {
                if(key === result.data.argument) {
                  fieldErrors = {
                    [mapApiFields[key]]: result.data.error_description,
                  }
                }
              }


              this.setState({
                fieldErrors
              })
          };

        this.setState({loading: false});
      })
    }
  }

  getInputClassError = field => {
		return !this.isValidField(field) ? 'error':'';
  };
  
  isValidField(field) {
    const {fieldErrors} = this.state;
		return !fieldErrors[field];
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
 
    console.log(selection)
    if (value[3] !== '9') {
        value = '+7(___)___-__-__';
        selection = { start: cursorPosition--, end: cursorPosition-- };
    }
 
    return {
      value,
      selection
    };
  }

  render(){

    if (this.context.servicesId.length < 1) {
      return <Redirect to='step-3'/>
    }

    var formatChars = {
      'a': '[А-Яа-яA-Za-z]',
      '9': '[0-9]',
    };

    return ( 
      <div>
        <div className="sing-up__body">
          <form className="form form_narrow" onSubmit={this.handleFormSubmit}>
            <div className="form__body">
              <div className="form__item">
                <Input className={'input ' + this.getInputClassError('name')} type='text' placeholder='Имя' name='name' onChange={this.handleInputChange('name')}/>
              </div>
              <div className="form__item">
                <Input className={'input ' + this.getInputClassError('phone')} type='tel' placeholder='Телефон' name='phone' onChange={this.handleInputChange('phone')} mask="+7(999)999-99-99" maskChar="_" beforeMaskedValueChange={this.beforeMaskedValueChange}/>
              </div>
              <div className="form__item">
                <Input className={'input ' + this.getInputClassError('carNumber')} type='text' placeholder='Номер машины' name='car_number' onChange={this.handleInputChange('carNumber')} mask="a999aa 999" formatChars={formatChars} maskChar="_"/>
              </div>
            </div>
            <div className="form__footer">
              <div className="agree">Заполняя форму, вы соглашаетесь с <a href={this.state.privacy ? this.state.privacy.PROPERTY_VALUES.docs : ''} target="_blank" rel="noopener noreferrer">Политикой о конфиденциальности</a>
              </div>
            </div>
            <div className="sing-up__footer">
              <div className="sing-up__footer-wrap">
                <Button className='button' type="submit">Записаться на мойку</Button>
                <Button className='button button_text' to='step-3'>Назад</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Step4;
